<template>
  <div class="page-box">
    <!-- 左面板视图 -->
    <div class="left-panel">
      <div class="search-section">
        <lz-icon
          type="iconfont"
          name="lzicon-search_outline icon"
          :size="15"
          @click.native="handleSearchClick">
        </lz-icon>
        <el-input
          class="search-input"
          v-model="searchKeyword"
          placeholder="搜索系统名称"
          clearable
          @keydown.enter.native="handleSearchClick"
          @clear="handleSearchInputClear">
        </el-input>
      </div>

      <div class="content-section mt-10">
        <div class="head-view">
          <lz-icon type="iconfont" name="lzicon-spherical" :size="14" color="#497CF6"></lz-icon>
          <span style="vertical-align: middle;">选择系统</span>
        </div>
        <div class="list-view">
          <template v-for="item in projectList">
            <li
              v-if="!isProjectHidden(item.projectCode)"
              :key="item.projectCode"
              :class="['list-item', { selected: item.projectCode == activeProjectCode }]"
              @click="handleProjectItemClick(item.projectCode, item.projectName)">{{ item.projectName }}
            </li>
          </template>
        </div>
      </div>
    </div>
    
    <!-- 中间分隔视图 -->
    <div class="middle-panel ml-20 mr-20"></div>

    <!-- 右面板视图 -->
    <div class="right-panel">
      <div class="title-section">
        <img src="~@/assets/images/authority-icon.png">
        <span class="title">设置该角色权限</span>
      </div>
      <div class="content-section pt-20">
        <box-view
          ref="boxView"
          :title="activeProjectName"
          :check-all="checkAll"
          :check-indeterminate="checkIndeterminate"
          @edited="isResEdited = true"
          @sync-state="syncPermissionCheckState"
          @sync-all-state="syncCheckAllState">
        </box-view>
      </div>
    </div>
  </div>
</template>

<script>

import permissionMixin from './mixins/permission'
import permissionSpecialMixin from './mixins/permissionSpecial'
import boxView from './box.vue'
import NodeStore from './node-store'

/**
 * 角色配置系统权限
 * @module @/view/organization/permission
 */
export default {
  mixins: [ permissionMixin, permissionSpecialMixin ],
  components: { boxView },
  props: {
    // 角色类型
    roleType: String,

    // 当前角色类型是否是“酒店角色”
    isRoleTypeHotel: Boolean,

    // 是否是“复制”操作
    isActionCopy: Boolean,

    hotelVid: {
      type: [Number, String]
    }
  },
  data () {
    return {
      searchKeyword: '',
      isSearching: false,

      // 是否产生编辑行为
      isResEdited: false,
      // 项目数据源
      projectData: [],
      // 当前选中的项目
      activeProjectCode: '',
      activeProjectName: ''
    }
  },
  computed: {
    // 权限视图引用
    permissionViewRef () {
      return this.$refs['boxView'];
    },

    // 项目列表
    projectList () {
      if (this.isSearching) {
        return this.projectData.filter(item => {
          return item.projectName.toLowerCase().indexOf(this.searchKeyword.trim().toLowerCase()) !== -1;
        });
      } else {
        return this.projectData;
      }
    },

    // 当前选中的项目数据
    activeProjectData () {
      return this.activeProjectCode ? this.projectData.find(item => item.projectCode===this.activeProjectCode) : null;
    }
  },
  methods: {
    /** 搜索相关 **/
    /**
     * 搜索点击事件
     */
    handleSearchClick () {
      if (this.searchKeyword.trim()) {
        this.isSearching = true;
      }
    },

    /**
     * 输入框 clear 事件
     */
    handleSearchInputClear () {
      this.isSearching = false;
    },


    /** 项目列表相关 **/
    /**
     * 项目点击事件
     * @param { String } code project code
     * @param { String } name project name
     */
    handleProjectItemClick (code, name) {
      if (code !== this.activeProjectCode) {
        this.activeProjectCode = code;
        this.activeProjectName = name;

        let project = this.activeProjectData;
        if (!project.store) {
          let store = new NodeStore({
            key: 'resourceId',
            data: project.resources
          });
          project.store = store;
        }
        this.permissionViewRef.setBoxData(project);
        this.processActiveCheckDataAndState();
      }
    },


    /** 外部调用 **/
    /**
     * 设置组件数据
     * @param { Array } data 项目数据源（不为空）
     */
    setData (data) {
      this.projectData = data;

      // 初始默认数据加载逻辑：
      // - 通用角色：第一个系统权限数据
      // - 门店角色：第一个非 disabled 系统权限数据
      // - “复制角色” 需初始化所有系统权限数据，以备直接提交保存
      for (let i = 0; i < this.projectData.length; i++) {
        const project = this.projectData[i];
        if (this.isProjectPOS(project.projectCode)) {
          this.processPOSRestData(project);
        }
        if (this.activeProjectCode && !this.isActionCopy) {
          continue;
        }
        const isHidden = this.isProjectHidden(project.projectCode);
        if (!this.activeProjectCode && !isHidden) {
          this.activeProjectCode = project.projectCode;
          this.activeProjectName = project.projectName;
          let store = new NodeStore({
            key: 'resourceId',
            data: project.resources
          });
          project.store = store;
          this.permissionViewRef.setBoxData(project);
        } else if (this.isActionCopy) {
          this.setPermissionCheckData(project.projectCode);
        }
      }

      this.processActiveCheckDataAndState();
    },

    /**
     * 获取已配置的项目权限数据
     * @note
     * - 新增、编辑时，权限字段数据只需传被点击渲染过的系统权限信息；复制时，需传所有系统权限信息
     * - “餐饮 - 餐厅”权限特殊处理
     * @returns { Object } 项目权限数据
     */
    getData () {
      let tmpData=[], restIds=[];
      this.ppData.forEach(data => {
        if (!this.isProjectDeleted(data.projectCode)) {
          tmpData.push({
            projectCode: data.projectCode,
            resourceIds: data.checkedKeys
          });
          // “餐饮-餐厅”权限特殊处理
          if (this.isProjectPOS(data.projectCode)) {
            restIds = data.specialCheckedKeys;
          }
        }
      });
      return { ppData: tmpData, restIds };
    },

    /**
     * “角色类型”改变
     * @note 特殊逻辑处理
     * - 角色类型为“门店角色”并且选中的项目 disabled，重置当前选中项目
     * - “餐饮”项目特殊逻辑处理
     */
    roleTypeChange () {
      this.$nextTick(() => {
        if (this.isProjectHidden(this.activeProjectCode)) {
          this.activeProjectCode = '';
          this.activeProjectName = '';
          this.permissionViewRef.setBoxData(this.activeProjectData);
        } else {
          this.processRestStateIfNeed();
        }
      });
    },

    /**
     * “绑定酒店”改变
     * @note “餐饮”项目特殊逻辑处理
     */
    selectHotelChange () {
      this.$nextTick(() => {
        this.processRestStateIfNeed();
      });
    },

    /**
     * 校验是否产生编辑行为
     * @returns { Boolean } true/false
     */
    checkIfTreeEdit () {
      return this.isResEdited;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";
  @import "@/style/mixins";

  .left-panel {
    width: 262px;
    height: 100%;
    display: inline-block;
    vertical-align: top;

    // 搜索板块
    .search-section {
      $_height: 30px;

      height: 32px;
      border: 1px solid $border-color;
      border-radius: 2px;

      .icon {
        width: $_height;
        height: $_height;
        line-height: $_height;
        color: $theme-color;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }

      .search-input {
        width: calc(100% - 30px);
        vertical-align: top;
        
        ::v-deep .el-input__inner {
          border: none;
          height: $_height;
          line-height: $_height;
        }

        ::v-deep .el-input__icon {
          line-height: $_height;
        }
      }
    }

    // 内容板块
    .content-section {
      height: calc(100% - 32px - 10px);

      .head-view {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
      }

      .head-view span {
        margin-left: 7px;
      }

      .list-view {
        height: calc(100% - 40px);
        overflow: auto;
      }

      .list-view .list-item {
        height: 40px;
        line-height: 40px;
        padding-left: 22px;
        cursor: pointer;
        @include no-wrap-single;
      }

      .list-view .list-item:hover {
        background-color: #EDF3FF;
      }
      
      .list-view .list-item.selected {
        color: $theme-color;
      }
    }
  }

  .middle-panel {
    width: 1px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    background-color: $border-color;
  }

  .right-panel {
    width: calc(100% - 262px - 41px);
    height: 100%;
    display: inline-block;
    vertical-align: top;

    .title-section {
      line-height: 1;
    }

    .title-section .title {
      font-size: 18px;
      font-weight: bold;
      vertical-align: middle;
    }

    .title-section img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      vertical-align: middle;
    }

    // 内容板块
    .content-section {
      height: calc(100% - 18px);
    }
  }
</style>
