var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "node-box" }, [
    _c(
      "div",
      { staticClass: "head-view" },
      [
        _c("span", { staticClass: "text ml-20" }, [
          _vm._v(_vm._s(_vm.title) + "权限列表")
        ]),
        _vm.data.length
          ? _c(
              "el-checkbox",
              {
                staticClass: "ml-20",
                attrs: {
                  value: _vm.checkAll,
                  indeterminate: _vm.checkIndeterminate
                },
                on: { change: _vm.handleCheckAllChange }
              },
              [_c("span", [_vm._v("全部选择")])]
            )
          : _vm._e()
      ],
      1
    ),
    _vm.data.length
      ? _c("div", { staticClass: "node-section" }, [
          _c(
            "div",
            { staticClass: "body" },
            _vm._l(_vm.data, function(child) {
              return _c("div", { key: child.key }, [
                _c("div", { staticClass: "row display__flex" }, [
                  _c("div", { staticClass: "col col-head display__flex" }, [
                    _c(
                      "div",
                      { staticClass: "cell display__flex align_items__center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "triangle-view",
                            on: {
                              click: function($event) {
                                return _vm.handleNodeExpandClick(child)
                              }
                            }
                          },
                          [
                            _c("i", {
                              class: [
                                "triangle-item",
                                { rotate: child.expanded }
                              ]
                            })
                          ]
                        ),
                        _c(
                          "el-checkbox",
                          {
                            attrs: {
                              title: child.data.resourceName,
                              value: child.data.checked
                            },
                            on: {
                              change: checked =>
                                _vm.handleNodeCheckChange(checked, child)
                            }
                          },
                          [_vm._v(_vm._s(child.data.resourceName) + " ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col col-body border-left" })
                ]),
                !child.isLeaf
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: child.expanded,
                            expression: "child.expanded"
                          }
                        ]
                      },
                      _vm._l(child.children, function(child) {
                        return _c(
                          "div",
                          {
                            key: child.key,
                            staticClass:
                              "row row-children display__flex align_items__center"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col col-head pl-50 display__flex align_items__center"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "cell" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          title: child.data.resourceName,
                                          value: child.data.checked
                                        },
                                        on: {
                                          change: checked =>
                                            _vm.handleNodeCheckChange(
                                              checked,
                                              child
                                            )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(child.data.resourceName) + " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "col col-body border-left pv-13" },
                              [
                                _vm._l(child.children, function(child) {
                                  return [
                                    child.visible
                                      ? _c(
                                          "div",
                                          {
                                            key: child.key,
                                            staticClass: "cell pl-15 mv-5"
                                          },
                                          [
                                            child.isLeaf
                                              ? [
                                                  _c(
                                                    "el-checkbox",
                                                    {
                                                      attrs: {
                                                        title:
                                                          child.data
                                                            .resourceName,
                                                        value:
                                                          child.data.checked
                                                      },
                                                      on: {
                                                        change: checked =>
                                                          _vm.handleNodeCheckChange(
                                                            checked,
                                                            child
                                                          )
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          child.data
                                                            .resourceName
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              : [
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        width: _vm.isNodeAllChildLeaf(
                                                          child
                                                        )
                                                          ? 240
                                                          : "",
                                                        trigger: "hover",
                                                        "popper-class":
                                                          "node-popover" +
                                                          (!_vm.isNodeAllChildLeaf(
                                                            child
                                                          )
                                                            ? " has-child"
                                                            : "")
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block"
                                                          },
                                                          attrs: {
                                                            slot: "reference"
                                                          },
                                                          slot: "reference"
                                                        },
                                                        [
                                                          _c(
                                                            "el-checkbox",
                                                            {
                                                              staticClass:
                                                                "underline",
                                                              attrs: {
                                                                title:
                                                                  child.data
                                                                    .resourceName,
                                                                value:
                                                                  child.data
                                                                    .checked
                                                              },
                                                              on: {
                                                                change: checked =>
                                                                  _vm.handleNodeCheckChange(
                                                                    checked,
                                                                    child
                                                                  )
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  child.data
                                                                    .resourceName
                                                                ) + " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "popover-body display__flex justify_content__space_between flex_wrap__wrap"
                                                        },
                                                        [
                                                          _vm.isNodeAllChildLeaf(
                                                            child
                                                          )
                                                            ? _vm._l(
                                                                child.children,
                                                                function(
                                                                  child
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        child.key,
                                                                      staticClass:
                                                                        "popover-cell mv-5"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-checkbox",
                                                                        {
                                                                          attrs: {
                                                                            title:
                                                                              child
                                                                                .data
                                                                                .resourceName,
                                                                            value:
                                                                              child
                                                                                .data
                                                                                .checked
                                                                          },
                                                                          on: {
                                                                            change: checked =>
                                                                              _vm.handleNodeCheckChange(
                                                                                checked,
                                                                                child
                                                                              )
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              child
                                                                                .data
                                                                                .resourceName
                                                                            ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              )
                                                            : [
                                                                _c(
                                                                  "cascader-panel-view",
                                                                  {
                                                                    attrs: {
                                                                      props: {
                                                                        children:
                                                                          "children",
                                                                        leaf:
                                                                          "isLeaf"
                                                                      },
                                                                      options:
                                                                        child.children
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function({
                                                                            node
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "el-checkbox",
                                                                                {
                                                                                  staticStyle: {
                                                                                    "padding-right":
                                                                                      "6px"
                                                                                  },
                                                                                  attrs: {
                                                                                    title:
                                                                                      node
                                                                                        .data
                                                                                        .resourceName,
                                                                                    value:
                                                                                      node
                                                                                        .data
                                                                                        .checked
                                                                                  },
                                                                                  on: {
                                                                                    change: checked =>
                                                                                      _vm.handleNodeCheckChange(
                                                                                        checked,
                                                                                        node
                                                                                      )
                                                                                  },
                                                                                  nativeOn: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      return (() => {}).apply(
                                                                                        null,
                                                                                        arguments
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "cascader-node__label",
                                                                                  attrs: {
                                                                                    title:
                                                                                      node
                                                                                        .data
                                                                                        .resourceName
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      node
                                                                                        .data
                                                                                        .resourceName
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }