var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading"
        }
      ],
      staticClass: "org-box page-box"
    },
    [
      _c(
        "lz-split",
        {
          attrs: { min: "320", max: "450" },
          model: {
            value: _vm.splitValue,
            callback: function($$v) {
              _vm.splitValue = $$v
            },
            expression: "splitValue"
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("resource-pool", {
                staticClass: "child-box",
                on: { "edit-member": _vm.handleEditMemberCallback }
              })
            ],
            1
          ),
          _c(
            "template",
            { slot: "right" },
            [
              _vm.isPoolTypeDept
                ? _c("dept-member", {
                    staticClass: "child-box node-detail",
                    on: { edit: _vm.handleEditMemberCallback }
                  })
                : _c("role-member", {
                    staticClass: "child-box node-detail",
                    on: { edit: _vm.handleEditMemberCallback }
                  })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "split-trigger__view",
              attrs: { slot: "trigger" },
              slot: "trigger"
            },
            [
              _c("i", {
                staticClass: "iconfont lzicon-dot_vertical",
                staticStyle: { color: "#ADB7C8", "vertical-align": "middle" }
              })
            ]
          )
        ],
        2
      ),
      _vm.showMEBox
        ? _c("member-edit-box", {
            attrs: {
              action: _vm.memberEditConfig.action,
              data: _vm.memberEditConfig.data
            },
            model: {
              value: _vm.showMEBox,
              callback: function($$v) {
                _vm.showMEBox = $$v
              },
              expression: "showMEBox"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }