<template>
  <div ref="splitCon" class="lz-split">
    <div :style="{ width: `${offset}px` }" class="lz-split-panel left-panel">
      <slot name="left" />
    </div>
    <div class="lz-split-trigger" @mousedown="handleMouseDownEvent">
      <slot name="trigger" />
    </div>
    <div :style="{ width: `${siblingOffset}px` }" class="lz-split-panel right-panel">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
/**
 * 封装自定义 Split 组件
 */
export default {
  name: 'LzSplit',
  props: {
    value: {
      type: [Number, String],
      default: 0.5
    },

    min: {
      type: [Number, String],
      default: '50'
    },

    max: {
      type: [Number, String],
      default: '100'
    }
  },
  data () {
    return {
      offset: 0,
      siblingOffset: 0,
      cacheOffset: 0,
      initOffset: 0,
      computedMin: 0,
      computedMax: 0,
      moving: false
    }
  },
  computed: {
    // value 是否像素
    isValuePixel () {
      return typeof this.value === 'string';
    }
  },
  mounted () {
    this.handleResize();
    this.setWindowResizeEventListener(true);
  },
  beforeDestroy () {
    this.setWindowResizeEventListener(false);
  },
  methods: {
    // 像素转换为比值
    pixelToRatio (value, relativeValue) {
      return parseFloat(value) / parseFloat(relativeValue);
    },

    /**
     * 比值转换为像素
     */
    ratioToPixel (value, relativeValue) {
      return value * parseFloat(relativeValue);
    },

    /**
     * 获取 container 容器 size
     */
    containerSize () {
      return this.$refs['splitCon'].clientWidth;
    },

    /**
     * 设置 window resize 事件
     */
    setWindowResizeEventListener (isAdd) {
      if (isAdd) {
        window.addEventListener('resize', this.handleResize, true);
      } else {
        window.removeEventListener('resize', this.handleResize, true);
      }
    },

    /**
     * 设置 mouse up/move 事件
     */
    setMouseEventListener (isAdd) {
      if (isAdd) {
        window.addEventListener('mouseup', this.handleMouseUpEvent);
        window.addEventListener('mousemove', this.handleMouseMoveEvent);
      } else {
        window.removeEventListener('mouseup', this.handleMouseUpEvent);
        window.removeEventListener('mousemove', this.handleMouseMoveEvent);
      }
    },

    /**
     * 处理边界值
     */
    computeBoundaryValue (type) {
      if (this.isValuePixel) {
        return typeof this[type] === 'string' ? this[type] : this.ratioToPixel(this[type], this.containerSize());
      } else {
        return typeof this[type] === 'number' ? this[type] : this.pixelToRatio(this[type], this.containerSize());
      }
    },

    /**
     * 处理 size 改变
     */
    handleResize () {
      this.$nextTick(() => {
        this.offset = this.isValuePixel ? this.value : this.ratioToPixel(this.value, this.containerSize());
        this.siblingOffset = this.containerSize() - this.offset;
      });
    },

    /**
     * Mouse down event
     */
    handleMouseDownEvent (e) {
      this.initOffset = e.pageX;
      this.cacheOffset = this.value;
      this.computedMin = this.computeBoundaryValue('min');
      this.computedMax = this.computeBoundaryValue('max');
      this.moving = true;
      this.setMouseEventListener(true);
    },

    /**
     * Mouse up event
     */
    handleMouseUpEvent (e) {
      this.moving = false;
      this.setMouseEventListener(false);
    },

    /**
     * Mouse move event
     */
    handleMouseMoveEvent (e) {
      if (this.moving) {
        const offset = e.pageX - this.initOffset;
        let value = this.isValuePixel ? (parseFloat(this.cacheOffset) + offset) + '' : this.pixelToRatio(this.containerSize()*this.cacheOffset + offset, this.containerSize());
        // 边界值处理
        if (parseFloat(value) < parseFloat(this.computedMin)) {
          value = this.computedMin;
        } else if (parseFloat(value) > parseFloat(this.computedMax)) {
          value = this.computedMax;
        }

        this.$emit('input', value);
        this.handleResize();
      }
    }
  }
}
</script>

<style scoped>
  .lz-split {
    width: 100%;
    height: 100%;
  }

  .lz-split-panel {
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }

  .lz-split-trigger {
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    user-select: none;
  }
</style>
