import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

// 通用角色列表
const roleGeneral = ajax.post(config.apiConf.serviceApi + 'roleInfo/listGeneralRole');
// 酒店角色列表
const roleHotel = ajax.post(config.apiConf.serviceApi + 'roleInfo/listHotelRole');
// 角色搜索
const roleSearch = ajax.post(config.apiConf.serviceApi + 'roleInfo/listByFuzzyName');
// 角色详情
const roleInfo = ajax.post(config.apiConf.serviceApi + 'roleInfo/getById');
// 角色复制详情
const roleCopyInfo = ajax.post(config.apiConf.serviceApi + 'roleInfo/copyById');
// 保存角色
const roleSave = ajax.post(config.apiConf.serviceApi + 'roleInfo/save');
// 删除角色
const roleDelete = ajax.post(config.apiConf.serviceApi + 'roleInfo/remove');


/** 公共 **/
// 角色类型下拉列表
const roleTypeSelectList = ajax.post(config.apiConf.serviceApi + 'roleInfo/roleType');
// 角色等级下拉列表
const roleLevelSelectList = ajax.post(config.apiConf.serviceApi + 'roleInfo/roleLevel');


/**
 * 角色相关 api
 */
export default {
  roleGeneral,
  roleHotel,
  roleSearch,
  roleInfo,
  roleCopyInfo,
  roleSave,
  roleDelete,

  roleTypeSelectList,
  roleLevelSelectList
}
