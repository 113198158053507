import { mapState, mapGetters } from 'vuex'

/**
 * Member edit sync dept mixin
 */
export default {
  computed: {
    ...mapState('org', [
      'deptTreeData'
    ]),

    ...mapGetters([
      'userOrgCode'
    ]),
  },
  methods: {
    /**
     * 按需同步“所属酒店”数据到“所属部门”和“可查看部门”
     * @note 在组织架构 tree 数据源中递归查找酒店名对应部门并分别选择（通过名称匹配）
     * - “所属酒店”选择的是非“集团”
     * - “所属部门”或“可查看部门”未选择
     */
    handleSyncDeptDataIfNeeded (hotelVid, hotelName) {
      if (hotelVid!=this.userOrgCode && (!this.formData.depts.length || !this.formData.showDepts.length)) {
        const syncData = this.getSyncDeptData(this.deptTreeData, hotelName);
        if (syncData.deptData) {
          if (!this.formData.depts.length) {
            this.formData.depts = [{
              id: syncData.deptData.deptId,
              name: syncData.deptData.deptName
            }];
          }
          if (!this.formData.showDepts.length) {
            this.formData.showDepts = [{
              id: syncData.topDeptData.deptId,
              name: syncData.topDeptData.deptName
            }];
          }
        }
      }
    },

    /**
     * 获取需要同步的部门数据
     * @returns { Object } 同步数据项
     */
    getSyncDeptData (treeData, hotelName) {
      let topDeptData = {};
      let tmpTreeData;
      if (treeData[0].permission) {
        tmpTreeData = treeData;
      } else {
        tmpTreeData = treeData[0].children;
      }
      const deptData = this._searchDeptByHotelName(hotelName, tmpTreeData, null, 0, topDeptData);
      return {
        deptData,
        topDeptData: topDeptData.node || deptData
      }
    },

    /**
     * 递归组织架构 tree 数据源，查找与酒店名匹配的部门
     * @note 按名称匹配查找
     * @note “可查看部门”需要查找出勾选部门所在层级的连续子部门的顶层部门（即单子部门勾选，父部门也勾选）
     * @param { String } hotelName 酒店名称
     * @param { Array } deptData 部门树数据源
     * @param { Object|Null } parentData 父节点数据项
     * @param { Number } tmp_level 数据节点层级
     * @param { Object } topDeptData 顶层缓存数据项
     * @returns { Object|Null } 部门数据项
     */
    _searchDeptByHotelName (hotelName, deptData, parentData, tmp_level, topDeptData) {
      let deptItem;
      if (deptData.length === 1) {
        if (!topDeptData.node || (parentData && parentData.tmp_level<=topDeptData.node.tmp_level)) {
          topDeptData.node = parentData;
        }
      } else {
        topDeptData.node = null;
      }

      for (let i = 0; i < deptData.length; i++) {
        const item = deptData[i];
        item.tmp_level = tmp_level;
        if (hotelName === item.deptName) {
          deptItem = item;
          if (!topDeptData.node || item.tmp_level<=topDeptData.node.tmp_level) {
            topDeptData.node = item;
          }
          break;
        } else if (item.children && item.children.length>0) {
          deptItem = this._searchDeptByHotelName(hotelName, item.children, item, tmp_level+1, topDeptData);
          if (deptItem) {
            break;
          }
        }
      }
      return deptItem;
    }
  }
}
