var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lz-drawer__box" },
    [
      _c(
        "div",
        { staticClass: "lz-drawer__body" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "lz-form--block",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "111px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名：", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入姓名" },
                    on: { blur: _vm.handleUsernameInputBlur },
                    model: {
                      value: _vm.formData.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "userName", $$v)
                      },
                      expression: "formData.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号：", prop: "accountUserName" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "16",
                      disabled: _vm.isAccountUserNameDisabled,
                      placeholder: _vm.accountPlaceholder
                    },
                    on: { blur: _vm.handleAccountInputBlur },
                    model: {
                      value: _vm.formData.accountUserName,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "accountUserName", $$v)
                      },
                      expression: "formData.accountUserName"
                    }
                  }),
                  _vm.isAccountUserNameDisabled
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: { click: _vm.handleResetPWDClick }
                        },
                        [_vm._v("重置密码 ")]
                      )
                    : _vm._e(),
                  _vm.accountLoading
                    ? _c("lz-icon", {
                        class: [
                          "loading-icon",
                          { loading: _vm.accountLoading }
                        ],
                        attrs: {
                          type: "iconfont",
                          name: "lzicon-loading",
                          size: 16
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isActionAdd
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码：", prop: "userPwd" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入密码" },
                        model: {
                          value: _vm.formData.userPwd,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "userPwd", $$v)
                          },
                          expression: "formData.userPwd"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: { click: _vm.handleCopyPWDClick }
                        },
                        [_vm._v("点击复制")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isUserHotelShowable
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属酒店：", prop: "userHotelId" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.formData.userHotelName,
                          readonly: "",
                          placeholder: "请选择所属酒店"
                        },
                        on: { focus: _vm.handleUserHotelClick }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "depts" } },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c(
                        "el-popover",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            width: 350,
                            trigger: "hover",
                            title: "所属部门影响"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "popover-reference display__flex align_items__center",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("span", [_vm._v("所属部门")]),
                              _c("lz-icon", {
                                attrs: {
                                  type: "iconfont",
                                  name: "lzicon-question_circle_outline",
                                  color: "#474F64",
                                  size: 12
                                }
                              })
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v("在人事管理系统中，哪个部门下可以查看该成员")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      value: _vm.userDeptName,
                      readonly: "",
                      placeholder: "请选择所属部门"
                    },
                    on: { focus: _vm.handleUserDeptClick }
                  })
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: _vm.isHotelRoleSelectable
                      ? "hotelRole"
                      : "generalRole"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c(
                        "el-popover",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            width: 350,
                            trigger: "hover",
                            title: "酒店角色影响"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "popover-reference display__flex align_items__center",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.isHotelRoleSelectable ? "酒店" : ""
                                  ) + "角色"
                                )
                              ]),
                              _c("lz-icon", {
                                attrs: {
                                  type: "iconfont",
                                  name: "lzicon-question_circle_outline",
                                  color: "#474F64",
                                  size: 12
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", [_vm._v("用户能够拥有所选角色的菜单权限")]),
                          _c("div", { staticClass: "mt-5" }, [
                            _vm._v("用户能够拥有所选酒店的数据权限")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm.isHotelRoleSelectable
                    ? [
                        _c("el-input", {
                          attrs: {
                            value: _vm.hotelRoleName,
                            readonly: "",
                            placeholder: "请分配酒店及角色"
                          },
                          on: { focus: _vm.handleHotelRoleClick }
                        })
                      ]
                    : [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "value-key": "id",
                              multiple: "",
                              "collapse-tags": "",
                              "multiple-limit": _vm.isTenantTypeBlocSetUser
                                ? 1
                                : 0,
                              placeholder: "请选择通用角色"
                            },
                            model: {
                              value: _vm.generalRoleList,
                              callback: function($$v) {
                                _vm.generalRoleList = $$v
                              },
                              expression: "generalRoleList"
                            }
                          },
                          _vm._l(_vm.generalRoleData, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item }
                            })
                          }),
                          1
                        )
                      ]
                ],
                2
              ),
              _vm.isTenantTypeBlocSetUser
                ? _c(
                    "el-form-item",
                    { attrs: { label: "酒管数据权限：", prop: "brands" } },
                    [
                      _c("el-input", {
                        attrs: {
                          value: _vm.brands.length ? "已选定，点击查看" : "",
                          readonly: "",
                          placeholder: "请选择酒管数据权限"
                        },
                        on: { focus: _vm.handleGroupRoleClick }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              false
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [
                          _c(
                            "el-popover",
                            {
                              staticStyle: { display: "inline-block" },
                              attrs: {
                                width: 350,
                                trigger: "hover",
                                title: "可查看餐厅影响"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "popover-reference display__flex align_items__center",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [
                                  _c("span", [_vm._v("可查看餐厅")]),
                                  _c("lz-icon", {
                                    attrs: {
                                      type: "iconfont",
                                      name: "lzicon-question_circle_outline",
                                      color: "#474F64",
                                      size: 12
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("div", [
                                _vm._v(
                                  "当用户拥有餐饮系统权限时，支持选择可查看餐厅"
                                )
                              ]),
                              _c("div", { staticClass: "mt-5" }, [
                                _vm._v("餐厅权限影响员工在餐饮系统能查看的餐厅")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c("el-input", {
                        attrs: {
                          value: _vm.formData.restIds.length
                            ? "已选定，点击查看"
                            : "",
                          readonly: "",
                          placeholder: "请选择可查看餐厅",
                          focus: "handleShowRestClick"
                        }
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "template",
                    { slot: "label" },
                    [
                      _c(
                        "el-popover",
                        {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            width: 350,
                            trigger: "hover",
                            title: "可查看部门影响"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "popover-reference display__flex align_items__center",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("span", [_vm._v("可查看部门")]),
                              _c("lz-icon", {
                                attrs: {
                                  type: "iconfont",
                                  name: "lzicon-question_circle_outline",
                                  color: "#474F64",
                                  size: 12
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(
                              "当用户拥有人事管理系统权限时，支持选择可查看部门"
                            )
                          ]),
                          _c("div", { staticClass: "mt-5" }, [
                            _vm._v(
                              "部门权限影响用户在人事管理系统中能查看的部门"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      value: _vm.formData.showDepts.length
                        ? "已选定，点击查看"
                        : "",
                      readonly: "",
                      placeholder: "请选择可查看部门"
                    },
                    on: { focus: _vm.handleShowDeptClick }
                  })
                ],
                2
              ),
              _vm.isUserJobShowable
                ? _c(
                    "el-form-item",
                    { attrs: { label: "职位：", prop: "jobId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", placeholder: "请选择职位" },
                          model: {
                            value: _vm.formData.jobId,
                            callback: function($$v) {
                              _vm.$set(_vm.formData, "jobId", $$v)
                            },
                            expression: "formData.jobId"
                          }
                        },
                        _vm._l(_vm.jobEnum, function(item) {
                          return _c("el-option", {
                            key: item.jobId,
                            attrs: { label: item.jobName, value: item.jobId }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "手机：", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "11", placeholder: "请输入手机号" },
                    on: { blur: _vm.handleMobileInputBlur },
                    model: {
                      value: _vm.formData.mobile,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "mobile", $$v)
                      },
                      expression: "formData.mobile"
                    }
                  }),
                  _vm.mobileLoading
                    ? _c("lz-icon", {
                        class: ["loading-icon", { loading: _vm.mobileLoading }],
                        attrs: {
                          type: "iconfont",
                          name: "lzicon-loading",
                          size: 16
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱：", prop: "email" } },
                [
                  _c("el-input", {
                    attrs: { type: "email", placeholder: "请输入邮箱" },
                    model: {
                      value: _vm.formData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "email", $$v)
                      },
                      expression: "formData.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "微信：" } },
                [
                  _c("el-input", {
                    attrs: {
                      value: _vm.formData.nickName || _vm.formData.openId,
                      disabled: ""
                    }
                  }),
                  _vm.isWXUnbindPermission &&
                  _vm.formData.openId &&
                  !_vm.formItemLoading
                    ? _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: { click: _vm.handleUnbindWeChatClick }
                        },
                        [_vm._v("取消绑定 ")]
                      )
                    : _vm._e(),
                  _vm.formItemLoading
                    ? _c("lz-icon", {
                        class: [
                          "loading-icon",
                          { loading: _vm.formItemLoading }
                        ],
                        attrs: {
                          type: "iconfont",
                          name: "lzicon-loading",
                          size: 16
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isOANoEnabled
                ? _c(
                    "el-form-item",
                    { attrs: { label: "艺科工号：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入艺科工号" },
                        model: {
                          value: _vm.formData.oaNo,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "oaNo", $$v)
                          },
                          expression: "formData.oaNo"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "lz-drawer__footer lz-drawer__footer__line" },
        [
          _vm.isStateUpdatable
            ? _c(
                "el-button",
                {
                  class: [
                    "lz-drawer__footer__button",
                    _vm.formData.state === 0
                      ? "button--enable"
                      : "button--disable"
                  ],
                  on: { click: _vm.handleUpdateStateClick }
                },
                [_vm._v(_vm._s(_vm.updateStateActionDesc()))]
              )
            : _vm._e(),
          _vm.isMemberDeletable
            ? _c(
                "el-button",
                {
                  staticClass: "lz-drawer__footer__button",
                  attrs: { type: "danger" },
                  on: { click: _vm.handleDeleteClick }
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.isMemberSaveable
            ? _c(
                "el-button",
                {
                  staticClass: "lz-drawer__footer__button confirm",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleSaveClick }
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "lz-drawer__footer__button",
              on: { click: _vm.handleCloseClick }
            },
            [_vm._v("取消")]
          )
        ],
        1
      ),
      _c(
        "lz-modal",
        {
          attrs: {
            name: "select-Modal",
            width: _vm.modalConfig.type === "hotelRole" ? "920px" : "586px",
            "mask-closable": false,
            "loading-enable": "",
            loading: _vm.modalLoading,
            "footer-hide": ""
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "lz-modal__header",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("span", { staticClass: "mr-5" }, [
                _vm._v(_vm._s(_vm.modalConfig.title))
              ]),
              _vm.modalConfig.type === "showDept"
                ? [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          width: 350,
                          trigger: "hover",
                          placement: "bottom"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "line-height": "1",
                              cursor: "help"
                            },
                            attrs: { slot: "reference" },
                            slot: "reference"
                          },
                          [
                            _c("lz-icon", {
                              staticStyle: {
                                "vertical-align": "top",
                                "margin-top": "2px"
                              },
                              attrs: {
                                type: "iconfont",
                                name: "lzicon-question_circle_outline",
                                color: "#474F64",
                                size: 12
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "如勾选父级部门，后续此部门下如新增部门，新增的部门也会给此成员自动添加部门权限。"
                            )
                          ]),
                          _c("div", { staticClass: "mt-5" }, [
                            _vm._v(
                              "如只勾选下级部门，则后续新增部门不会给此用户自动添加权限。"
                            )
                          ])
                        ])
                      ]
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: { height: "580px" },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.modalConfig.type === "hotel"
                ? [
                    _vm.showModal
                      ? _c("select-hotel", {
                          attrs: {
                            refer: "member",
                            hotelVid: _vm.formData.userHotelId,
                            hotelName: _vm.formData.userHotelName
                          },
                          on: {
                            loading: loading => {
                              _vm.modalLoading = loading
                            },
                            confirm: _vm.handleSelectHotelCallback
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.modalConfig.type === "dept"
                ? [
                    _vm.showModal
                      ? _c("select-dept", {
                          attrs: {
                            depts: _vm.formData.depts,
                            "show-checkbox": true,
                            "check-strictly": true
                          },
                          on: {
                            loading: loading => {
                              _vm.modalLoading = loading
                            },
                            confirm: _vm.handleSelectUserDeptCallback
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.modalConfig.type === "showDept"
                ? [
                    _vm.showModal
                      ? _c("select-dept", {
                          attrs: {
                            depts: _vm.formData.showDepts,
                            "show-checkbox": true
                          },
                          on: {
                            loading: loading => {
                              _vm.modalLoading = loading
                            },
                            confirm: _vm.handleSelectShowDeptCallback
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.modalConfig.type === "showRest"
                ? [
                    _vm.showModal
                      ? _c("select-rest", {
                          attrs: {
                            restIds: _vm.formData.restIds,
                            "show-checkbox": true
                          },
                          on: {
                            loading: loading => {
                              _vm.modalLoading = loading
                            },
                            confirm: _vm.handleSelectShowRestCallback
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.modalConfig.type === "hotelRole"
                ? [
                    _vm.showModal
                      ? _c("select-hotel-role", {
                          attrs: {
                            "general-role-list": _vm.generalRoleList,
                            "hotel-role-list": _vm.enabledHotelRoleList,
                            "region-list": _vm.regionList,
                            "area-list": _vm.areaList,
                            brandList: _vm.brandList,
                            notifiable: _vm.roleNotifiable
                          },
                          on: {
                            loading: loading => {
                              _vm.modalLoading = loading
                            },
                            complete: _vm.handleSelectHotelRoleCallback,
                            notice: function($event) {
                              _vm.roleNotifiable = false
                            }
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.modalConfig.type === "groupRole"
                ? [
                    _vm.showModal
                      ? _c("group-role", {
                          attrs: {
                            blocCascadeList: _vm.blocCascadeList,
                            brands: _vm.brands,
                            isInitData: _vm.isInitData
                          },
                          on: {
                            "update:isInitData": function($event) {
                              _vm.isInitData = $event
                            },
                            "update:is-init-data": function($event) {
                              _vm.isInitData = $event
                            },
                            saveGroupRole: _vm.saveGroupRole
                          },
                          model: {
                            value: _vm.showModal,
                            callback: function($$v) {
                              _vm.showModal = $$v
                            },
                            expression: "showModal"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _c("lz-dialog", {
        attrs: { width: "360px", title: "是否确定删除？" },
        on: { confirm: _vm.handleDeleteDialogConfirm },
        model: {
          value: _vm.showDeleteDialog,
          callback: function($$v) {
            _vm.showDeleteDialog = $$v
          },
          expression: "showDeleteDialog"
        }
      }),
      _c(
        "lz-dialog",
        {
          attrs: {
            name: "reset-pwd",
            width: "360px",
            title: _vm.dialogConfig.title,
            "mask-closable": false,
            "header-hide": false,
            "loading-enable": "",
            loading: _vm.dialogLoading
          },
          on: { confirm: _vm.handleResetPasswordConfirm },
          model: {
            value: _vm.showResetPwdDialog,
            callback: function($$v) {
              _vm.showResetPwdDialog = $$v
            },
            expression: "showResetPwdDialog"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "body" }, slot: "body" },
            [
              _c(
                "transition",
                { attrs: { name: "fade-common" } },
                [
                  _vm.showResetPwdDialog
                    ? _c("reset-password", { ref: "resetPWD" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }