import { render, staticRenderFns } from "./roleBox.vue?vue&type=template&id=7f0a37cb&scoped=true"
import script from "./roleBox.vue?vue&type=script&lang=js"
export * from "./roleBox.vue?vue&type=script&lang=js"
import style0 from "./roleBox.vue?vue&type=style&index=0&id=7f0a37cb&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f0a37cb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\workspace-abroad-hrms-web\\abroad-hrms-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f0a37cb')) {
      api.createRecord('7f0a37cb', component.options)
    } else {
      api.reload('7f0a37cb', component.options)
    }
    module.hot.accept("./roleBox.vue?vue&type=template&id=7f0a37cb&scoped=true", function () {
      api.rerender('7f0a37cb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/organization/member/roleBox.vue"
export default component.exports