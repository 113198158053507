var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showable
    ? _c(
        "div",
        {
          class: [
            "root-node",
            { enabled: !_vm.disbaled },
            "display__flex",
            "align_items__center",
            "justify_content__space_between"
          ]
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tree-row__prefix flex_grow__1 display__flex align_items__center",
              on: { click: _vm.handleNodeCurrentChange }
            },
            [
              _vm.title
                ? _c("lz-icon", {
                    staticStyle: { "margin-left": "8px" },
                    attrs: {
                      type: "iconfont",
                      name: "tree-folder__icon lzicon-folder",
                      size: 14
                    }
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  class: [
                    "tree-node__title is-root",
                    { selected: _vm.selected }
                  ],
                  attrs: { title: _vm.title }
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            ],
            1
          ),
          !_vm.disbaled
            ? _c("div", { staticClass: "suffix-view tree-row__suffix" }, [
                _vm.addable
                  ? _c(
                      "div",
                      {
                        staticClass: "tree-row__action__item",
                        on: { click: _vm.handleAddNodeCallback }
                      },
                      [
                        _c("i", {
                          staticClass: "iconfont lzicon-add_circle_outline"
                        })
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }