var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "splitCon", staticClass: "lz-split" }, [
    _c(
      "div",
      {
        staticClass: "lz-split-panel left-panel",
        style: { width: `${_vm.offset}px` }
      },
      [_vm._t("left")],
      2
    ),
    _c(
      "div",
      {
        staticClass: "lz-split-trigger",
        on: { mousedown: _vm.handleMouseDownEvent }
      },
      [_vm._t("trigger")],
      2
    ),
    _c(
      "div",
      {
        staticClass: "lz-split-panel right-panel",
        style: { width: `${_vm.siblingOffset}px` }
      },
      [_vm._t("right")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }