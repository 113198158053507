<template>
  <div class="lz-drawer__box">
    <div class="lz-drawer__body">
      <el-form
        ref="form"
        class="lz-form--block"
        :model="formData"
        :rules="rules"
        label-width="111px">
        <el-form-item label="姓名：" prop="userName">
          <el-input
            v-model="formData.userName"
            maxlength="8"
            placeholder="请输入姓名"
            @blur="handleUsernameInputBlur">
          </el-input>
        </el-form-item>
        <el-form-item label="账号：" prop="accountUserName">
          <el-input
            v-model="formData.accountUserName"
            maxlength="16"
            :disabled="isAccountUserNameDisabled"
            :placeholder="accountPlaceholder"
            @blur="handleAccountInputBlur">
          </el-input>
          <el-button
            v-if="isAccountUserNameDisabled"
            style="margin-left: 10px;"
            type="text"
            @click="handleResetPWDClick">重置密码
          </el-button>
          <lz-icon
            v-if="accountLoading"
            :class="['loading-icon', { 'loading': accountLoading }]"
            type="iconfont"
            name="lzicon-loading"
            :size="16">
          </lz-icon>
        </el-form-item>
        <el-form-item v-if="isActionAdd" label="密码：" prop="userPwd">
          <el-input v-model="formData.userPwd" placeholder="请输入密码"></el-input>
          <el-button style="margin-left: 10px;" type="text" @click="handleCopyPWDClick">点击复制</el-button>
        </el-form-item>
        <el-form-item v-if="isUserHotelShowable" label="所属酒店：" prop="userHotelId">
          <el-input
            :value="formData.userHotelName"
            readonly
            placeholder="请选择所属酒店"
            @focus="handleUserHotelClick">
          </el-input>
        </el-form-item>
        <el-form-item prop="depts">
          <template slot="label">
            <el-popover
              style="display: inline-block;"
              :width="350"
              trigger="hover"
              title="所属部门影响">
              <div slot="reference" class="popover-reference display__flex align_items__center">
                <span>所属部门</span>
                <lz-icon type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
              </div>
              <span>在人事管理系统中，哪个部门下可以查看该成员</span>
            </el-popover>
          </template>

          <el-input
            :value="userDeptName"
            readonly
            placeholder="请选择所属部门"
            @focus="handleUserDeptClick">
          </el-input>
        </el-form-item>
        <el-form-item :prop="isHotelRoleSelectable ? 'hotelRole' : 'generalRole'">
          <template slot="label">
            <el-popover
              style="display: inline-block;"
              :width="350"
              trigger="hover"
              title="酒店角色影响">
              <div slot="reference" class="popover-reference display__flex align_items__center">
                <span>{{ isHotelRoleSelectable ? '酒店' : '' }}角色</span>
                <lz-icon type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
              </div>
              <div>用户能够拥有所选角色的菜单权限</div>
              <div class="mt-5">用户能够拥有所选酒店的数据权限</div>
            </el-popover>
          </template>

          <template v-if="isHotelRoleSelectable">
            <el-input
              :value="hotelRoleName"
              readonly
              placeholder="请分配酒店及角色"
              @focus="handleHotelRoleClick">
            </el-input>
          </template>
          <template v-else>
            <el-select
              v-model="generalRoleList"
              value-key="id"
              multiple
              collapse-tags
              :multiple-limit="isTenantTypeBlocSetUser ? 1 : 0"
              placeholder="请选择通用角色">
              <el-option
                v-for="item in generalRoleData"
                :key="item.id"
                :label="item.name"
                :value="item">
              </el-option>
            </el-select>
          </template>
        </el-form-item>
        <el-form-item v-if="isTenantTypeBlocSetUser" label="酒管数据权限：" prop="brands">
          <!-- <el-select
            :value="formData.blocSet"
            multiple
            collapse-tags
            filterable
            placeholder="请选择酒管数据权限"
            @remove-tag="handleBlocSetSelectRemove">
            <el-option
              v-for="item in blocSetDatas"
              :key="item.blocCode"
              :label="item.blocName"
              :value="item.blocCode"
              :disabled="isBlocItemDisabled(item.blocCode)"
              @click.native="handleBlocSetOptionClick(item.blocCode)">
            </el-option>
          </el-select> -->

          <el-input
            :value="brands.length ? '已选定，点击查看' : ''"
            readonly
            placeholder="请选择酒管数据权限"
            @focus="handleGroupRoleClick">
          </el-input>
        </el-form-item>
        <!-- 暂不支持配置 -->
        <el-form-item v-if="false">
          <template slot="label">
            <el-popover
              style="display: inline-block;"
              :width="350"
              trigger="hover"
              title="可查看餐厅影响">
              <div slot="reference" class="popover-reference display__flex align_items__center">
                <span>可查看餐厅</span>
                <lz-icon type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
              </div>
              <div>当用户拥有餐饮系统权限时，支持选择可查看餐厅</div>
              <div class="mt-5">餐厅权限影响员工在餐饮系统能查看的餐厅</div>
            </el-popover>
          </template>

          <el-input
            :value="formData.restIds.length ? '已选定，点击查看' : ''"
            readonly
            placeholder="请选择可查看餐厅"
            focus="handleShowRestClick">
          </el-input>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <el-popover
              style="display: inline-block;"
              :width="350"
              trigger="hover"
              title="可查看部门影响">
              <div slot="reference" class="popover-reference display__flex align_items__center">
                <span>可查看部门</span>
                <lz-icon type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
              </div>
              <div>当用户拥有人事管理系统权限时，支持选择可查看部门</div>
              <div class="mt-5">部门权限影响用户在人事管理系统中能查看的部门</div>
            </el-popover>
          </template>

          <el-input
            :value="formData.showDepts.length ? '已选定，点击查看' : ''"
            readonly
            placeholder="请选择可查看部门"
            @focus="handleShowDeptClick">
          </el-input>
        </el-form-item>
        <el-form-item v-if="isUserJobShowable" label="职位：" prop="jobId">
          <el-select
            v-model="formData.jobId"
            filterable
            placeholder="请选择职位">
            <el-option
              v-for="item in jobEnum"
              :key="item.jobId"
              :label="item.jobName"
              :value="item.jobId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机：" prop="mobile">
          <el-input
            v-model="formData.mobile"
            maxlength="11"
            placeholder="请输入手机号"
            @blur="handleMobileInputBlur">
          </el-input>
          <lz-icon
            v-if="mobileLoading"
            :class="['loading-icon', { 'loading': mobileLoading }]"
            type="iconfont"
            name="lzicon-loading"
            :size="16">
          </lz-icon>
        </el-form-item>
        <el-form-item label="邮箱：" prop="email">
          <el-input v-model="formData.email" type="email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="微信：">
          <!-- 存在 nickName 字段则显示，不存在显示 openId -->
          <el-input :value="formData.nickName || formData.openId" disabled></el-input>
          <el-button
            v-if="isWXUnbindPermission && formData.openId && !formItemLoading"
            style="margin-left: 10px;"
            type="text"
            @click="handleUnbindWeChatClick">取消绑定
          </el-button>
          <lz-icon
            v-if="formItemLoading"
            :class="['loading-icon', { 'loading': formItemLoading }]"
            type="iconfont"
            name="lzicon-loading"
            :size="16">
          </lz-icon>
        </el-form-item>
        <el-form-item v-if="isOANoEnabled" label="艺科工号：">
          <el-input v-model="formData.oaNo" placeholder="请输入艺科工号"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="lz-drawer__footer lz-drawer__footer__line">
      <el-button v-if="isStateUpdatable" :class="['lz-drawer__footer__button', formData.state===0 ? 'button--enable' : 'button--disable']" @click="handleUpdateStateClick">{{ updateStateActionDesc() }}</el-button>
      <el-button v-if="isMemberDeletable" type="danger" class="lz-drawer__footer__button" @click="handleDeleteClick">删除</el-button>
      <el-button v-if="isMemberSaveable" type="primary" class="lz-drawer__footer__button confirm" @click="handleSaveClick">确定</el-button>
      <el-button class="lz-drawer__footer__button" @click="handleCloseClick">取消</el-button>
    </div>

    <!-- Modal 视图 -->
    <lz-modal
      v-model="showModal"
      name="select-Modal"
      :width="modalConfig.type==='hotelRole' ? '920px' : '586px'"
      :mask-closable="false"
      loading-enable
      :loading="modalLoading"
      footer-hide>
      <div slot="header" class="lz-modal__header">
        <span class="mr-5">{{ modalConfig.title }}</span>
        <template v-if="modalConfig.type === 'showDept'">
          <el-popover
            :width="350"
            trigger="hover"
            placement="bottom">
            <div slot="reference" style="display: inline-block; line-height: 1; cursor: help;">
              <lz-icon style="vertical-align: top; margin-top: 2px;" type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
            </div>
            <div>
              <div>如勾选父级部门，后续此部门下如新增部门，新增的部门也会给此成员自动添加部门权限。</div>
              <div class="mt-5">如只勾选下级部门，则后续新增部门不会给此用户自动添加权限。</div>
            </div>
          </el-popover>
        </template>
      </div>

      <div slot="body" style="height: 580px;">
        <!-- 所属酒店 -->
        <template v-if="modalConfig.type === 'hotel'">
          <select-hotel
            v-if="showModal"
            v-model="showModal"
            refer="member"
            :hotelVid="formData.userHotelId"
            :hotelName="formData.userHotelName"
            @loading="(loading) => { modalLoading = loading }"
            @confirm="handleSelectHotelCallback">
          </select-hotel>
        </template>

        <!-- 所属部门 -->
        <template v-else-if="modalConfig.type === 'dept'">
          <select-dept
            v-if="showModal"
            v-model="showModal"
            :depts="formData.depts"
            :show-checkbox="true"
            :check-strictly="true"
            @loading="(loading) => { modalLoading = loading }"
            @confirm="handleSelectUserDeptCallback">
          </select-dept>
        </template>

        <!-- 可查看部门 -->
        <template v-else-if="modalConfig.type === 'showDept'">
          <select-dept
            v-if="showModal"
            v-model="showModal"
            :depts="formData.showDepts"
            :show-checkbox="true"
            @loading="(loading) => { modalLoading = loading }"
            @confirm="handleSelectShowDeptCallback">
          </select-dept>
        </template>

        <!-- 可查看餐厅 -->
        <template v-else-if="modalConfig.type === 'showRest'">
          <select-rest
            v-if="showModal"
            v-model="showModal"
            :restIds="formData.restIds"
            :show-checkbox="true"
            @loading="(loading) => { modalLoading = loading }"
            @confirm="handleSelectShowRestCallback">
          </select-rest>
        </template>
        
        <!-- 酒店角色 -->
        <template v-else-if="modalConfig.type === 'hotelRole'">
          <select-hotel-role
            v-if="showModal"
            v-model="showModal"
            :general-role-list="generalRoleList"
            :hotel-role-list="enabledHotelRoleList"
            :region-list="regionList"
            :area-list="areaList"
            :brandList="brandList"
            :notifiable="roleNotifiable"
            @loading="(loading) => { modalLoading = loading }"
            @complete="handleSelectHotelRoleCallback"
            @notice="roleNotifiable = false">
          </select-hotel-role>
        </template>

        <!-- 酒管数据权限 -->
        <template v-else-if="modalConfig.type === 'groupRole'">
          <group-role
            v-if="showModal"
            v-model="showModal"
            :blocCascadeList="blocCascadeList"
            :brands="brands"
            :isInitData.sync="isInitData"
            @saveGroupRole="saveGroupRole">
          </group-role>
        </template>
      </div>
    </lz-modal>

    <!-- 删除成员 -->
    <lz-dialog
      v-model="showDeleteDialog"
      width="360px"
      title="是否确定删除？"
      @confirm="handleDeleteDialogConfirm">
    </lz-dialog>

    <!-- 重置密码 -->
    <lz-dialog
      v-model="showResetPwdDialog"
      name="reset-pwd"
      width="360px"
      :title="dialogConfig.title"
      :mask-closable="false"
      :header-hide="false"
      loading-enable
      :loading="dialogLoading"
      @confirm="handleResetPasswordConfirm">
      <div slot="body">
        <transition name="fade-common">
          <reset-password
            ref="resetPWD"
            v-if="showResetPwdDialog">
          </reset-password>
        </transition>
      </div>
    </lz-dialog>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import { regexPwd } from '@/libs/regexUtil'
import fetchMixin from './mixins/fetch'
import syncDeptMixin from './mixins/syncDept'
import editOEMMixin from './mixins/editOEM'
import blocSetMixin from './mixins/blocSet'
import checkAccount from './mixins/checkAccount'
import ResetPassword from './resetPWD.vue'
import SelectHotel from '../common/selectHotel.vue'
import SelectDept from '../dept/selectDept.vue'
import SelectRest from './selectRest.vue'
import SelectHotelRole from './hotel-role/selectHotelRole.vue'
import GroupRole from './group-role/index.vue'

/**
 * 成员编辑
 * @module @/view/organization
 */
export default {
  name: 'MemberEdit',
  mixins: [ fetchMixin, syncDeptMixin, editOEMMixin, blocSetMixin, checkAccount ],
  components: {
    ResetPassword,
    SelectHotel,
    SelectDept,
    SelectRest,
    SelectHotelRole,
    GroupRole
  },
  props: {
    // 操作类型（1-新增，2-编辑）
    action: {
      type: Number,
      default: 1
    },

    // 成员 id
    id: Number
  },
  data () {
    return {
      formData: {
        userName: '',
        accountUserName: '',
        userPwd: '',
        mobile: '',
        email: '',
        nickName: '',
        openId: '',
        state: 1,
        // 所属酒店
        userHotelId: '',
        userHotelName: '',
        // 所属部门
        depts: [],
        // 可查看部门
        showDepts: [],
        // 可查看餐厅
        restIds: [],
        // 酒管数据权限
        blocSet: [],
        brands: [],
        // 职位
        jobId: '',
        // 艺科工号
        oaNo: ''
      },
      // 已配置的通用角色数据
      // 注意：无权限的角色数据，标记为：disabled；集团租户：不屏蔽无权限数据；其他：屏蔽无权限数据
      generalRoleList: [],
      // 已配置的酒店角色数据
      // 注意：无权限酒店角色数据标记为：disabled，不外显；但保存时需要一并提交
      hotelRoleList: [],
      // 已勾选的城市、区域、平台数据
      regionList: [],
      areaList: [],
      brandList: [],
      rules: {
        userName: [
          { required: true, whitespace: true, message: '请输入姓名', trigger: 'blur' },
          { type: 'string', max: 8, message: '姓名不能超过8位', trigger: 'click' }
        ],
        accountUserName: [
          { required: true, max: 16, pattern: /^[a-zA-Z0-9]+$/, trigger: 'blur', validator: (rule, value, callback) => {
            // 编辑时(已存在账号)不进行校验
            if (this.isAccountUserNameDisabled) {
              callback();
            } else {
              if (rule.required && value.length===0) {
                callback(new Error('请输入账号'));
              } else if (rule.max && rule.max<value.length) {
                callback(new Error('账号不能超过16位'));
              } else if (rule.pattern && !rule.pattern.test(value)) {
                callback(new Error('账号只能包含数字和字母'));
              } else if (this.accountError) {
                // TODO：2.0与1.x过渡逻辑，全量切换后需删除
                callback(new Error(this.accountError));
              } else {
                callback();
              }
            }
          }}
        ],
        userPwd: [
          { required: true, trigger: 'blur', validator: regexPwd }
        ],
        mobile: [
          { type: 'string', pattern: /^\d{11}$/, trigger: 'blur', validator: (rule, value, callback) => {
            if (value && !rule.pattern.test(value)) {
              callback(new Error('手机号应由11位数字组成'));
            } else if (value && this.mobileError) {
              // TODO：2.0与1.x过渡逻辑，全量切换后需删除
              callback(new Error(this.mobileError));
            } else {
              callback();
            }
          }}
        ],
        email: [
          { type: 'email', message: '请填写正确的邮箱', trigger: 'blur' }
        ],
        userHotelId: [
          { required: true, trigger: 'click', validator: (rule, value, callback) => {
            if (rule.required && (!value || value==0)) {
              callback(new Error('请选择所属酒店'));
            } else {
              callback();
            }
          }}
        ],
        depts: [
          { required: true, type: 'array', message: '请选择所属部门', trigger: 'click' }
        ],
        hotelRole: [
          { required: true, trigger: 'click', validator: (rule, value, callback) => {
            if (rule.required && !this.enabledHotelRoleList.length) {
              callback(new Error('必须分配酒店和角色'));
            } else {
              callback();
            }
          }}
        ],
        generalRole: [
          { required: true, trigger: 'change', validator: (rule, value, callback) => {
            if (rule.required && !this.generalRoleList.length) {
              callback(new Error('请选择角色'));
            } else {
              callback();
            }
          }}
        ],
        brands: [
          { required: true, type: 'array', message: '请选择酒管数据权限', trigger: 'change' }
        ]
      },

      // Modal 配置
      modalLoading: false,
      showModal: false,
      modalConfig: {
        title: '',
        // modal 类型
        type: ''
      },
      dialogLoading: false,
      // 删除成员弹窗
      showDeleteDialog: false,
      // 重置密码
      showResetPwdDialog: false,
      dialogConfig: {
        title: ''
      },
      formItemLoading: false,

      // 酒店角色视图是否可提示（新增/编辑只提示一次）
      roleNotifiable: true,
      checkedNode: '', // 保存上次选中的Node节点
      // 选择的酒店数据权限 数据
      brands: [],
      // 没有操作过酒店权限 还是默认数据
      isInitData: false
    }
  },
  computed: {
    ...mapState('org', [
      'deptTreeData',
      'generalRoleData'
    ]),

    ...mapState('attendance', [
      'jobEnum'
    ]),

    ...mapGetters([
      'userInfo',
      'pageButtonPermission',
      'isTenantTypeBlocUser',
      'isTenantTypeBlocSetUser'
    ]),

    ...mapGetters('org', [
      'isTreeRootNode'
    ]),

    // “所属部门”显示名称
    userDeptName () {
      if (this.formData.depts.length > 1) {
        return '已选定，点击查看';
      } else if (this.formData.depts.length === 1) {
        let dept = this.formData.depts[0];
        return this.isTreeRootNode(dept.id) ? this.deptTreeData[0].deptName||'已选定，点击查看' : dept.name;
      } else {
        return '';
      }
    },

    // “酒店角色”显示名称
    hotelRoleName () {
      return this.enabledHotelRoleList.length ? `已分配${this.enabledHotelRoleList.length}家酒店` : '';
    },

    // 是否是“新增”操作
    isActionAdd () {
      return this.action === 1;
    },

    // 是否是“编辑”操作
    isActionEdit () {
      return this.action === 2;
    },

    // 账号是否 disabled 状态（需兼容PMS存在无“账号”成员场景）
    isAccountUserNameDisabled () {
      return this.isActionEdit && this.formData.accountUserName!=='';
    },

    // 所属酒店
    // “所属酒店”是否可配置（“集团租户”用户可配置）
    isUserHotelShowable () {
      return this.isTenantTypeBlocUser;
    },

    // 酒店角色
    // “酒店角色”是否可选择酒店（“集团租户”用户选择酒店）
    isHotelRoleSelectable () {
      return this.isTenantTypeBlocUser;
    },

    // “职位”是否可配置（“集团租户”用户可配置）
    isUserJobShowable () {
      return this.isTenantTypeBlocUser;
    },

    // 外显酒店角色列表（当前操作人拥有权限）
    enabledHotelRoleList () {
      return this.hotelRoleList.filter(item => !item.disabled);
    },

    // 是否具有“微信取消绑定”权限
    isWXUnbindPermission () {
      return this.pageButtonPermission('hrms/organization', 'unbindUserWXButton');
    },

    // 是否具有“状态更新”权限
    isStateUpdatePermission () {
      return this.pageButtonPermission('hrms/organization', 'updateUserStatusButton');
    },

    // 是否具有“成员删除”权限
    isMemberDeletePermission () {
      return this.pageButtonPermission('hrms/organization', 'deleteUserButton');
    },

    // 是否具有“成员保存”权限
    isMemberSavePermission () {
      return this.pageButtonPermission('hrms/organization', 'saveUserInfoButton');
    },

    // 更新成员“状态”按钮是否可见（不能停用、启用自己）
    isStateUpdatable () {
      return this.isActionEdit
            && this.isStateUpdatePermission
            && this.id!=this.userInfo.userId
            && this.formData.state!=='';
    },

    // 成员”删除“按钮是否可见
    isMemberDeletable () {
      return this.isActionEdit
            && this.isMemberDeletePermission
            && this.id != this.userInfo.userId
            && this.formData.state!=='';
    },

    // 成员“保存”按钮是否可见
    isMemberSaveable () {
      return this.isMemberSavePermission;
    }
  },
  watch: {},
  created () {
    this.intiData();
  },
  methods: {
    ...mapActions('attendance', [
      'getJobSelectList'
    ]),

    intiData () {
      if (this.isActionAdd) {
        this.createPassword();
      } else {
        this.getMemberInfo();
      }
      this.checkCanEditOANo();

      if (this.isUserJobShowable) {
        // 获取职位枚举数据
        this.getJobSelectList();
      }
    },

    /**
     * 设置视图 loading 状态
     */
    viewLoading (loading) {
      this.$emit('loading', loading);
    },


    /**
     * 用户名 input blur 事件
     * @note 新增用户，输入用户名完成后，根据配置调用自动生成账号接口
     */
    handleUsernameInputBlur () {
      if (this.isActionAdd && this.formData.userName.trim().length) {
        this.createAccount();
      }
    },

    /**
     * 重置密码事件
     */
    handleResetPWDClick () {
      this.dialogConfig.title = '重置密码';
      this.showResetPwdDialog = true;
      this.generatePassword();
    },

    /**
     * 生成随机密码后处理逻辑
     */
    processAfterGeneratePWD (data) {
      this.$refs['resetPWD'].setData(data);
    },

    handleResetPasswordConfirm () {
      this.$refs['resetPWD'].getData(res => {
        if (res.result) {
          this.resetAndSendPassword(res.content);
        }
      });
    },

    /**
     * 复制密码点击事件
     */
    handleCopyPWDClick () {
      if (this.formData.userPwd.length === 0) {
        return;
      }
      let tempInput = document.createElement('input');
      tempInput.setAttribute('value', this.formData.userPwd);
      document.body.appendChild(tempInput);
      tempInput.select();
      let result = document.execCommand('copy');
      document.body.removeChild(tempInput);
      if (result) {
        this.$notice.success('密码复制成功');
      } else {
        console.error('Member: copy passwod failure');
      }
    },

    /**
     * 解绑微信号点击事件
     */
    handleUnbindWeChatClick () {
      if (this.formData.openId) {
        this.unbindWX();
      }
    },

    /**
     * 更新成员状态点击事件
     */
    handleUpdateStateClick () {
      this.updateState();
    },

    /**
     * 更新成员状态操作按钮文案
     */
    updateStateActionDesc () {
      return this.formData.state==1 ? '停用' : '启用';
    },

    /**
     * 删除点击事件
     */
    handleDeleteClick () {
      this.showDeleteDialog = true;
    },

    handleDeleteDialogConfirm () {
      this.showDeleteDialog = false;
      this.deleteMember();
    },

    /**
     * 保存点击事件
     */
    handleSaveClick () {
      this.saveHandle = true
      this.$refs['form'].validate(valid => {
        // TODO：2.0与1.x过渡逻辑，全量切换后需删除
        if (valid && !this.accountLoading && !this.mobileLoading) {
          this.saveMemberInfo();
          this.saveHandle = false
        }
      });
    },

    /**
     * 取消点击事件
     */
    handleCloseClick () {
      this.$emit('close');
    },


    /** 所属酒店相关 **/
    /**
     * “所属酒店”点击事件
     */
    handleUserHotelClick () {
      this.modalConfig.title = '所属酒店';
      this.modalConfig.type = 'hotel';
      this.showModal = true;
    },

    /**
     * “所属酒店”选择回调事件
     * @param { Object } data 选择的数据对象
     */
    handleSelectHotelCallback (data) {
      this.showModal = false;
      this.formData.userHotelId = data.hotelVid;
      this.formData.userHotelName = data.hotelName.trim();

      this.handleSyncDeptDataIfNeeded(this.formData.userHotelId, this.formData.userHotelName);
    },


    /** 所属部门相关 **/
    /**
     * “所属部门”点击事件
     */
    handleUserDeptClick () {
      this.modalConfig.title = '所属部门';
      this.modalConfig.type = 'dept';
      this.showModal = true;
    },

    /**
     * “所属部门”选择回调事件
     * @param { Array } data 部门信息
     */
    handleSelectUserDeptCallback (data) {
      this.showModal = false;
      this.formData.depts = data;
    },


    /** 可查看部门相关 **/
    /**
     * “可查看部门”点击事件
     */
    handleShowDeptClick () {
      this.modalConfig.title = '可查看部门';
      this.modalConfig.type = 'showDept';
      this.showModal = true;
    },

    /**
     * “可查看部门”选择回调事件
     * @param { Array } data 部门信息
     */
    handleSelectShowDeptCallback (data) {
      this.showModal = false;
      this.formData.showDepts = data;
    },


    /** 可查看餐厅 **/
    /**
     * “可查看餐厅”点击事件
     */
    handleShowRestClick () {
      this.modalConfig.title = '可查看餐厅';
      this.modalConfig.type = 'showRest';
      this.showModal = true;
    },

    /**
     * “可查看餐厅”选择回调事件
     * @param { Array } data 餐厅信息
     */
    handleSelectShowRestCallback (data) {
      this.showModal = false;
      this.formData.restIds = data;
    },


    /** 酒店角色 **/
    /**
     * “酒店角色”点击事件
     */
    handleHotelRoleClick () {
      this.modalConfig.title = '分配酒店和角色';
      this.modalConfig.type = 'hotelRole';
      this.showModal = true;
    },

    handleGroupRoleClick() {
      this.modalConfig.title = '分配酒管数据权限';
      this.modalConfig.type = 'groupRole';
      this.showModal = true;
    },

    /**
     * “酒店角色”选择回调事件
     * @param { Object|Null } groupRoleItem 通用角色数据对象
     * @param { Array } hotelRoleList 酒店角色列表（包含有权限的酒店角色）
     * @param { Array } regionList 已勾选的城市数据
     * @param { Array } areaList 已勾选的区域数据
     * @param { Array } brandList 已勾选的品牌数据
     */
    handleSelectHotelRoleCallback (generalRoleItem, hotelRoleList, regionList, areaList, brandList) {
      this.showModal = false;
      if (generalRoleItem) {
        this.generalRoleList = [generalRoleItem];
      }
      let diabledHotelRoleList = this.hotelRoleList.filter(item => item.disabled);
      // 需要保存的数据（有酒店权限数据 + 无酒店权限数据）
      this.hotelRoleList = [...hotelRoleList, ...diabledHotelRoleList];
      this.regionList = regionList;
      this.areaList = areaList;
      this.brandList = brandList;
    },
    // 选择
    saveGroupRole(data) {
      this.brands = data
      this.formData.brands = data
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .lz-form--block {
    .popover-reference {
      line-height: 1;
      cursor: help;

      &::after {
        content: '：';
      }
    }

    .loading-icon {
      line-height: 1;
      margin-left: 10px;

      &.loading {
        animation: rotate-loop 1s linear infinite;
      }
    }
  }

  .button--enable {
    color: #FFF;
    background-color: $success-color;
    border-color: $success-color;
  }

  .button--disable {
    color: #FFF;
    background-color: #ADB7C8;
    border-color: #ADB7C8;
  }

  
  // 旋转动画
  @keyframes rotate-loop {
    0% {
      transform: rotate(0deg);
    }
    
    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
