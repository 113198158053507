var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-pool" }, [
    _c(
      "div",
      { staticClass: "search-view" },
      [
        _c("el-input", {
          staticClass: "search-input",
          attrs: {
            placeholder: _vm.placeholder,
            maxlength: "15",
            clearable: ""
          },
          on: { clear: _vm.handleSearchInputClear },
          nativeOn: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleSearchClick.apply(null, arguments)
            }
          },
          model: {
            value: _vm.keyword,
            callback: function($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword"
          }
        }),
        _c("lz-icon", {
          attrs: {
            type: "iconfont",
            name: "lzicon-search_outline icon",
            size: 15
          },
          nativeOn: {
            click: function($event) {
              return _vm.handleSearchClick.apply(null, arguments)
            }
          }
        })
      ],
      1
    ),
    _vm.isSearching
      ? _c(
          "div",
          { staticClass: "list-view" },
          [
            _vm.searchList && _vm.searchList.length
              ? [
                  _c(
                    "ul",
                    _vm._l(_vm.searchList, function(item) {
                      return _c(
                        "li",
                        {
                          key: item.type + "_" + item[_vm.props.value],
                          class: [
                            "list-item pl-5 pr-5",
                            {
                              active:
                                _vm.selectedValue === item[_vm.props.value]
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.handleSearchItemClick(item)
                            }
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getListItemDesc(item)))
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]
              : _vm.searchList && _vm.searchList.length === 0
              ? [_vm._m(0)]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-data-box" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-data.png") } }),
      _c("div", { staticClass: "text" }, [_vm._v("没有查询到你想要的结果呢～")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }