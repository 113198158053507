var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-body" },
    [
      _vm._m(0),
      _c("el-input", {
        class: ["form-item__content", { "is-error": _vm.error }],
        attrs: { clearable: "" },
        on: { blur: _vm.handleResetPwdInputBlur },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      }),
      _vm.error
        ? _c("div", { staticClass: "form-item__error" }, [
            _vm._v(_vm._s(_vm.message))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-item__title" }, [
      _c("span", [_vm._v("新密码")]),
      _c("span", { staticClass: "suffix" }, [
        _vm._v("（您也可以手动设置密码）")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }