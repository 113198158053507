<template>
  <div class="page-box">
    <div class="modal-body lz-modal__body group-modal">
      <!-- <el-tree :data="treeData" ref="myTree" class="group-tree" :props="defaultProps" :default-expanded-keys="['0']" node-key="value" show-checkbox></el-tree> -->
      <el-tree ref="myTree" class="group-tree" :data="treeData" :props="defaultProps" node-key="id" show-checkbox check-strictly default-expand-all @check-change="handleCheckChange"> </el-tree>
      <div class="lz-modal__footer lz-modal__footer__line">
        <el-button type="primary" size="small" class="lz-modal__footer__button confirm" @click="handleSaveClick">确定</el-button>
        <el-button size="small" class="lz-modal__footer__button cancel" @click="handleCloseClick">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    blocCascadeList: {
      type: Array,
      default: (_) => [],
    },
    // 已选择的节点数据
    brands: {
      type: Array,
      default: (_) => [],
    },
    isInitData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'brands',
        label: 'label',
      },
      treeData: [],
      // 是否在初始化中
      isInitTreeFlag: false,
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.treeData = JSON.parse(JSON.stringify(this.blocCascadeList));
    this.initParent(this.treeData, null);
    this.$nextTick(() => {
      // 如果酒管权限手动选择过 isInitData为true，则回显手动选择的数据
      if (this.isInitData) {
        this.isInitTreeFlag = true;
        this.checkNodes(this.brands);
      } else {
        // 初始化的时候，直接拿tree的数据源里面的selected 去默认勾选
        this.treeData.forEach((node) => {
          this.checkSelectedNodes(node);
        });
      }
    });
  },
  methods: {
    // 初始化tree数据 并给节点添加其parent节点
    initParent(data, parent) {
      data.forEach((node) => {
        node.parent = parent;
        if (node.brands) {
          this.initParent(node.brands, node);
        }
      });
    },
    // 找到父节点
    findParentInResult(result, blocCode) {
      for (let i = 0; i < result.length; i++) {
        if (result[i].blocCode === blocCode) {
          return result[i];
        }
      }
      return null;
    },
    // 获取选中的节点 并处理数据结构
    getCheckedData() {
      const checkedNodes = this.$refs.myTree.getCheckedNodes();
      const result = [];

      checkedNodes.forEach((node) => {
        if (node.value == 0) {
          result.push({ selected: true, blocCode: node.value, brandCodes: [], brandIds: [] });
        } else if (node.blocCode) {
          // 如果节点为第二级
          let parent = this.findParentInResult(result, node.blocCode);

          if (!parent) {
            parent = {
              selected: true,
              blocCode: node.blocCode,
              brandCodes: [],
              brandIds: [],
            };
            result.push(parent);
          }
        } else if (node.brandCode) {
          // 如果节点为第三级
          let parent = this.findParentInResult(result, node.parent.value);
          if (!parent) {
            parent = {
              selected: false,
              blocCode: node.parent.value,
              brandCodes: [],
              brandIds: [],
            };
            result.push(parent);
          }
          parent.brandCodes.push(node.brandCode);
          parent.brandIds.push(node.id);
        }
      });

      return result;
    },

    // 回显的时候勾选上tree的节点
    checkNodes(data) {
      const checkedKeys = [];

      data.forEach((item) => {
        if (item.selected) checkedKeys.push(item.blocCode);

        item.brandIds.forEach((brandId) => {
          checkedKeys.push(brandId);
        });
      });

      this.$refs.myTree.setCheckedKeys(checkedKeys);
      // 需要异步去关闭初始化状态，不然只选择父级不选择子级的时候，父级的勾选会触发tree的check事件从而把子级都勾选中
      setTimeout(() => {
        this.isInitTreeFlag = false;
      }, 10);
    },
    // 监听tree的勾选
    handleCheckChange(data, checked) {
      if (this.isInitTreeFlag) return;
      this.$nextTick(() => {
        this.setCheckedNodes(data, checked);
        // 对于非第一级的节点 取消勾选的时候，如果父级是勾选的，则需要取消勾选父级
        if (!checked && data.parent) {
          this.cancelCheckParent(data);
        }
      });
    },
    // 勾选父级后 把队友的子级勾选上
    setCheckedNodes(node, status) {
      if (node.brands) {
        node.brands.forEach((child) => {
          this.$refs.myTree.setChecked(child.id, status);
          this.setCheckedNodes(child, status);
        });
      }
    },
    // 取消勾选子级的时候，如果父级是勾选的状态，那么把父级的也给取消勾选
    cancelCheckParent(node) {
      const target = this.$refs.myTree.getNode(node?.parent?.id);
      if (target.checked) {
        this.isInitTreeFlag = true;
        this.$refs.myTree.setChecked(target.data.id, false);
        this.$refs.myTree.setChecked('0', false);
        setTimeout(() => {
          this.isInitTreeFlag = false;
        }, 10);
      }
    },
    // 初始化的时候 根据数据源的selected 遍历勾选节点
    checkSelectedNodes(node) {
      if (node.selected) {
        this.$refs.myTree.setChecked(node.id, true);
      }
      if (node.brands) {
        node.brands.forEach((child) => {
          this.checkSelectedNodes(child);
        });
      }
    },

    // 确定选择
    handleSaveClick() {
      const brands = this.getCheckedData();
      this.$emit('update:isInitData', true);
      this.$emit('saveGroupRole', brands);
      this.$emit('input', false);
    },
    // 关闭点击事件
    handleCloseClick() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.group-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.group-tree {
  flex: 1;
  overflow-y: auto;
}
</style>
