var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-list-box display__flex flex_direction__column",
      style: { width: _vm.width + "px", height: _vm.height + "px" }
    },
    [
      _vm.loading
        ? [
            _c(
              "span",
              {
                staticClass: "search-list-box__placeholder",
                style: { "line-height": _vm.height + "px" }
              },
              [_vm._v("数据加载中，请稍候...")]
            )
          ]
        : [
            _vm.listItems.length > 0
              ? _c(
                  "div",
                  { staticClass: "search-wrapper" },
                  [
                    _c("lz-icon", {
                      attrs: {
                        type: "iconfont",
                        name: "lzicon-search_outline search-icon",
                        size: 16
                      }
                    }),
                    _c("el-input", {
                      staticClass: "search-input",
                      attrs: { placeholder: "搜索角色名称" },
                      model: {
                        value: _vm.searchValue,
                        callback: function($$v) {
                          _vm.searchValue = $$v
                        },
                        expression: "searchValue"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.listItems.length > 0
              ? _c(
                  "ul",
                  { staticClass: "list-wrapper" },
                  _vm._l(_vm.filterListItems, function(item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        class: [
                          "list-item",
                          { selected: item.id == _vm.selectedId }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.handleListItemClick(index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                )
              : _c(
                  "span",
                  {
                    staticClass: "search-list-box__placeholder",
                    style: { "line-height": _vm.height + "px" }
                  },
                  [_vm._v("没有查询到你想要的结果呢～")]
                )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }