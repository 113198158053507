<template>
  <div class="search-list-box display__flex flex_direction__column" :style="{ width: width+'px', height: height+'px' }">
    <template v-if="loading">
      <span :style="{ 'line-height': height+'px' }" class="search-list-box__placeholder">数据加载中，请稍候...</span>
    </template>
    <template v-else>
      <div class="search-wrapper" v-if="listItems.length > 0">
        <lz-icon type="iconfont" name="lzicon-search_outline search-icon" :size="16"></lz-icon>
        <el-input v-model="searchValue" placeholder="搜索角色名称" class="search-input"></el-input>
      </div>
      <ul class="list-wrapper" v-if="listItems.length > 0">
        <li v-for="(item, index) in filterListItems" :key="index" :class="['list-item', { selected: item.id == selectedId }]" @click="handleListItemClick(index)">{{ item.name }}</li>
      </ul>
      <span v-else :style="{ 'line-height': height+'px' }" class="search-list-box__placeholder">没有查询到你想要的结果呢～</span>
    </template>
  </div>
</template>

<script>
/**
 * 搜索列表组件
 * @param @/view/components/SearchList
 */
export default {
  name: 'SearchList',
  props: {
    width: {
      type: Number,
      default: 220
    },

    height: {
      type: Number,
      default: 180
    },

    // 数据加载中标识
    loading: {
      type: Boolean,
      default: false
    },

    // 数据源列表
    listItems: {
      type: Array,
      default: function () {
        return []
      }
    },

    // 初始选中项 Id
    defalutSelId: {
      type: [Number, String],
      default: -1
    }
  },
  computed: {
    filterListItems: function () {
      if (this.searchValue==='') {
        return this.listItems;
      } else {
        return this.listItems.filter(this.createFilter(this.searchValue));
      }
    },
  },
  data () {
    return {
      searchValue: '',
      // 当前选中项 Id
      selectedId: this.defalutSelId,
    }
  },
  methods: {
    /**
     * 前端搜索逻辑
     */
    createFilter(queryString) {
      return item => {
        return item.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
      };
    },

    /**
     * 搜索事件
     */
    handleSearchClick() {
      
    },
    
    /**
     * 列表项点击事件
     */
    handleListItemClick(index) {
      let selItem = this.filterListItems[index];
      this.selectedId = selItem.id;
      
      let idx = this.listItems.findIndex(item => item.id === selItem.id);
      this.$emit('selected', { index: idx, id: selItem.id });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";
  @import "@/style/mixins";

  .search-list-box {
    .search-list-box__placeholder {
      text-align: center;
    }

    .search-wrapper {
      border-bottom: 1px solid rgba(0,0,0,0.06);
      .search-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: $theme-color;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
      }
      .search-input {
        width: calc(100% - 32px);
        vertical-align: top;
        
        ::v-deep .el-input__inner {
          border: none;
        }
      }
    }

    .list-wrapper {
      overflow: auto;
      padding: 3px 0;
    }
    .list-wrapper .list-item {
      height: 32px;
      line-height: 32px;
      padding: 0 12px;
      cursor: pointer;
      @include no-wrap-single;
    }
    .list-wrapper .list-item.selected {
      color: $theme-color;
    }
    .list-wrapper .list-item:hover {
      background-color: #EDF3FF;
    }
  }
</style>