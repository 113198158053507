var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "member-box",
        {
          ref: "memberBox",
          attrs: {
            refer: "role",
            title: _vm.activeRoleName,
            loading: _vm.boxLoading
          },
          on: {
            "page-change": _vm.handlePaginationOnChangeCallback,
            edit: _vm.hanleTableItemEditCallback
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "action-box",
              attrs: { slot: "action" },
              slot: "action"
            },
            [
              _c(
                "div",
                { staticClass: "select-view" },
                [
                  _c(
                    "el-select",
                    { attrs: { value: _vm.filterForm.state } },
                    _vm._l(_vm.stateEnum, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                        nativeOn: {
                          click: function($event) {
                            return _vm.handleStateOptionClick(item.value)
                          }
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "button-view" }, [
                _vm.isMemberAddPermission
                  ? _c(
                      "div",
                      {
                        staticClass: "action-button__icon--basic action-button",
                        class: { disabled: _vm.isActionDisabled },
                        on: { click: _vm.handleAddClick }
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus prefix-icon" }),
                        _c("span", { staticClass: "suffix-text" }, [
                          _vm._v("添加成员")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.isMemberRemovePermission
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "action-button__icon--basic action-button red",
                        class: { disabled: _vm.isActionDisabled },
                        on: { click: _vm.handleMemberRemoveClick }
                      },
                      [
                        _c("span", { staticClass: "suffix-text" }, [
                          _vm._v("移除成员")
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        ]
      ),
      _c(
        "lz-modal",
        {
          attrs: {
            name: "select-member",
            width: "586px",
            title: _vm.modalConfig.title,
            "mask-closable": false,
            "loading-enable": "",
            loading: _vm.modalLoading,
            "footer-hide": ""
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { height: "580px" },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.showModal
                ? _c("select-member", {
                    on: {
                      loading: loading => {
                        _vm.modalLoading = loading
                      },
                      confirm: _vm.handleSelectMemberCallback
                    },
                    model: {
                      value: _vm.showModal,
                      callback: function($$v) {
                        _vm.showModal = $$v
                      },
                      expression: "showModal"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("lz-dialog", {
        attrs: {
          name: "remove-member",
          width: "360px",
          title: _vm.dialogConfig.title,
          "confirm-button-text": "确认",
          "mask-closable": !_vm.dialogLoading,
          "loading-enable": "",
          loading: _vm.dialogLoading
        },
        on: { confirm: _vm.handleDialogConfirmCallback },
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }