var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-tree", {
    ref: "tree",
    staticClass: "tree-view",
    class: "row-h" + _vm.treeProps.rowHeight,
    attrs: {
      "node-key": _vm.nodeKey,
      "expand-on-click-node": false,
      "highlight-current": false,
      "show-checkbox": _vm.showCheckbox,
      "check-strictly": _vm.checkStrictlyEnable,
      "empty-text": "暂无数据",
      props: _vm.treeProps,
      data: _vm.treeData,
      "default-expanded-keys": _vm.defaultExpandedKeys,
      "render-content": _vm.renderTreeContent
    },
    on: {
      "current-change": _vm.handleTreeCurrentChange,
      "node-expand": _vm.handleTreeNodeExpand,
      "node-collapse": _vm.handleTreeNodeCollapse,
      check: _vm.handleTreeNodeCheck
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }