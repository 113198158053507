<template>
  <lz-drawer
    v-model="show"
    name="member-edit-box"
    :width="560"
    :mask-closable="false"
    loading-enable
    :loading="drawerLoading"
    @close="handleDrawerCloseCallback">
    <!-- 自定义 header -->
    <div slot="header" class="drawer-header lz-drawer__header">
      <span>{{ title }}</span>
      <div v-if="isActionEdit" :class="['capsule-view', state===1 ? 'green' : 'gray']">{{ stateDesc }}</div>
    </div>

    <member-edit
      v-if="show"
      v-model="show"
      :action="action"
      :id="this.data&&this.data.id"
      @loading="(loading) => { drawerLoading = loading }"
      @detail="handleEditDetailCallback"
      @action="handleEditActionCallback"
      @close="handleEditCloseCallback">
    </member-edit>
  </lz-drawer>
</template>

<script>

import MemberEdit from './edit.vue'

/**
 * 成员编辑容器
 * @module @/view/organization
 */
export default {
  name: 'MemberEditBox',
  components: {
    MemberEdit
  },
  props: {
    // 操作类型（1-新增，2-编辑）
    action: {
      type: Number,
      default: 1
    },

    // 成员信息
    data: Object
  },
  data () {
    return {
      drawerLoading: false,
      show: false,
      title: '',
      state: 1,
      stateDesc: ''
    }
  },
  computed: {
    // 是否是“编辑”操作
    isActionEdit () {
      return this.action === 2;
    },
  },
  created () {
    this.initData ();
  },
  methods: {
    initData () {
      if (this.isActionEdit) {
        this.$store.commit('org/setActiveMember', this.data);
        this.title = '编辑成员';
      } else {
        this.$store.commit('org/setActiveMember', null);
        this.title = '新增成员';
      }
      this.$nextTick(() => {
        this.show = true;
      });
    },

    /**
     * 获取成员详情回调事件
     * @param { Object } data 成员数据
     */
    handleEditDetailCallback (data) {
      this.state = data.state;
      this.stateDesc = data.state==1 ? '启用' : '停用';
    },

    /**
     * edit 事件操作回调事件
     */
    handleEditActionCallback (action) {
      this.$store.commit('org/setMemberAction', action);
    },

    /**
     * edit 关闭回调事件
     */
    handleEditCloseCallback () {
      this.show = false;
      this.$emit('input', false);
    },


    /**
     * Drawer 组件关闭回调事件
     */
    handleDrawerCloseCallback () {
      this.handleEditCloseCallback();
    }
  }
}
</script>

<style lang="scss" scoped>
  .drawer-header {
    // 胶囊控件
    .capsule-view {
      width: 60px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px;
      font-size: 12px;
      color: #FFF;
      text-align: center;
    }

    .capsule-view.green {
      background-color: #6ACE96;
    }

    .capsule-view.gray {
      background-color: #ADB7C8;
    }
  }
</style>
