var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c("div", { staticClass: "left-panel" }, [
      _c(
        "div",
        { staticClass: "search-section" },
        [
          _c("lz-icon", {
            attrs: {
              type: "iconfont",
              name: "lzicon-search_outline icon",
              size: 15
            },
            nativeOn: {
              click: function($event) {
                return _vm.handleSearchClick.apply(null, arguments)
              }
            }
          }),
          _c("el-input", {
            staticClass: "search-input",
            attrs: { placeholder: "搜索系统名称", clearable: "" },
            on: { clear: _vm.handleSearchInputClear },
            nativeOn: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleSearchClick.apply(null, arguments)
              }
            },
            model: {
              value: _vm.searchKeyword,
              callback: function($$v) {
                _vm.searchKeyword = $$v
              },
              expression: "searchKeyword"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "content-section mt-10" }, [
        _c(
          "div",
          { staticClass: "head-view" },
          [
            _c("lz-icon", {
              attrs: {
                type: "iconfont",
                name: "lzicon-spherical",
                size: 14,
                color: "#497CF6"
              }
            }),
            _c("span", { staticStyle: { "vertical-align": "middle" } }, [
              _vm._v("选择系统")
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "list-view" },
          [
            _vm._l(_vm.projectList, function(item) {
              return [
                !_vm.isProjectHidden(item.projectCode)
                  ? _c(
                      "li",
                      {
                        key: item.projectCode,
                        class: [
                          "list-item",
                          {
                            selected: item.projectCode == _vm.activeProjectCode
                          }
                        ],
                        on: {
                          click: function($event) {
                            return _vm.handleProjectItemClick(
                              item.projectCode,
                              item.projectName
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.projectName) + " ")]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ])
    ]),
    _c("div", { staticClass: "middle-panel ml-20 mr-20" }),
    _c("div", { staticClass: "right-panel" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "content-section pt-20" },
        [
          _c("box-view", {
            ref: "boxView",
            attrs: {
              title: _vm.activeProjectName,
              "check-all": _vm.checkAll,
              "check-indeterminate": _vm.checkIndeterminate
            },
            on: {
              edited: function($event) {
                _vm.isResEdited = true
              },
              "sync-state": _vm.syncPermissionCheckState,
              "sync-all-state": _vm.syncCheckAllState
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-section" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/authority-icon.png") }
      }),
      _c("span", { staticClass: "title" }, [_vm._v("设置该角色权限")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }