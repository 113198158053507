var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c("div", { staticClass: "modal-body lz-modal__body" }, [
      _c("div", { staticClass: "left-panel pr-20" }, [
        _c("div", { staticClass: "title-section" }, [
          _c("span", [_vm._v("选择酒店")]),
          _c("span", [
            _vm._v("（已选："),
            _c("span", { staticClass: "highlight-title" }, [
              _vm._v(_vm._s(_vm.hotelCheckedItems.length))
            ]),
            _vm._v("家酒店）")
          ]),
          _c(
            "div",
            { staticClass: "tips-view" },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: 290, trigger: "hover", placement: "bottom" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "icon",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("lz-icon", {
                        attrs: {
                          type: "iconfont",
                          name: "lz-icon lzicon-question_circle_outline",
                          size: 14,
                          color: "#474F64"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "font-size": "12px" } }, [
                    _c("div", [
                      _vm._v(
                        "选择地域、品牌、省市，后续如此范围内如新增酒店，新增酒店也会按通用角色给此用户自动添加。"
                      )
                    ]),
                    _c("div", { staticClass: "mt-5" }, [
                      _vm._v("只勾选酒店，则后续新增酒店不会给此用户自动添加。")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "content-section" }, [
          _c(
            "div",
            { staticClass: "search-section" },
            [
              _c("search-list", {
                ref: "searchList",
                attrs: {
                  placeholder: "搜索酒店名",
                  props: _vm.searchProps,
                  showCheckbox: true,
                  "search-list": _vm.searchList
                },
                on: {
                  search: _vm.handleSearchCallback,
                  cancel: _vm.handleSearchCancelCallback,
                  "check-change": _vm.handleSearchCheckChangeCallback
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSearchingState(),
                  expression: "!isSearchingState()"
                }
              ],
              staticClass: "tree-section pt-10"
            },
            [
              _c(
                "div",
                { staticClass: "filter-view" },
                _vm._l(_vm.filterTypeEnum, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.value,
                      class: [
                        "filter-view__item",
                        { actived: _vm.filterType === item.value }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.handleFilterItemClick(item.value)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "action-view" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "action-item",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.handleTreeCheckAllClick(true)
                        }
                      }
                    },
                    [_vm._v("选择全部")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "action-item",
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.handleTreeCheckAllClick(false)
                        }
                      }
                    },
                    [_vm._v("取消选中")]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.isFilterTypeRegion,
                      expression: "this.isFilterTypeRegion"
                    }
                  ],
                  staticClass: "tree-content pb-10"
                },
                [
                  _c("el-tree", {
                    ref: "regionTree",
                    staticClass: "tree-view",
                    attrs: {
                      data: _vm.regionTreeData,
                      "node-key": "id",
                      "show-checkbox": "",
                      "check-strictly": "",
                      "highlight-current": false,
                      "empty-text": _vm.regionEmptyText,
                      "default-checked-keys": _vm.regionCheckedKeys,
                      "render-content": _vm.renderTreeContent
                    },
                    on: {
                      check: _vm.handleTreeNodeCheck,
                      "node-expand": _vm.handleTreeNodeExpand
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.isFilterTypeArea,
                      expression: "this.isFilterTypeArea"
                    }
                  ],
                  staticClass: "tree-content pb-10"
                },
                [
                  _c("el-tree", {
                    ref: "areaTree",
                    staticClass: "tree-view",
                    attrs: {
                      data: _vm.areaTreeData,
                      "node-key": "id",
                      "show-checkbox": "",
                      "check-strictly": "",
                      "highlight-current": false,
                      "empty-text": _vm.areaEmptyText,
                      "default-checked-keys": _vm.areaCheckedKeys,
                      "render-content": _vm.renderTreeContent
                    },
                    on: {
                      check: _vm.handleTreeNodeCheck,
                      "node-expand": _vm.handleTreeNodeExpand
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.isFilterTypeBrand,
                      expression: "this.isFilterTypeBrand"
                    }
                  ],
                  staticClass: "tree-content pb-10"
                },
                [
                  _c("el-tree", {
                    ref: "brandTree",
                    staticClass: "tree-view",
                    attrs: {
                      data: _vm.brandTreeData,
                      "node-key": "id",
                      "show-checkbox": "",
                      "check-strictly": "",
                      "highlight-current": false,
                      "empty-text": _vm.brandEmptyText,
                      "default-checked-keys": _vm.brandCheckedKeys,
                      "render-content": _vm.renderTreeContent
                    },
                    on: {
                      check: _vm.handleTreeNodeCheck,
                      "node-expand": _vm.handleTreeNodeExpand
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "right-panel pl-20" }, [
        _c("div", { staticClass: "title-section" }, [
          _c("span", [_vm._v("分配酒店角色")]),
          _c(
            "div",
            { staticClass: "tips-view ml-10" },
            [
              _c(
                "el-popover",
                {
                  attrs: { width: 290, trigger: "hover", placement: "bottom" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "icon",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _c("lz-icon", {
                        attrs: {
                          type: "iconfont",
                          name: "lz-icon lzicon-question_circle_outline",
                          size: 14,
                          color: "#474F64"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "font-size": "12px" } }, [
                    _c("div", [
                      _vm._v("选择通用角色可批量设置所选酒店的角色权限。")
                    ]),
                    _c("div", { staticClass: "mt-5" }, [
                      _vm._v("选择酒店角色，只能设置对应酒店下创建的角色权限。")
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "content-section" }, [
          _c(
            "div",
            { staticClass: "select-section" },
            [
              _c("span", { staticClass: "prefix" }, [
                _vm._v("批量设置通用角色")
              ]),
              _c(
                "el-popover",
                {
                  attrs: {
                    width: 220,
                    placement: "bottom",
                    "visible-arrow": false,
                    "popper-class": "popper-select"
                  },
                  model: {
                    value: _vm.showRoleSelect,
                    callback: function($$v) {
                      _vm.showRoleSelect = $$v
                    },
                    expression: "showRoleSelect"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      slot: "reference",
                      value:
                        (_vm.generalRoleItem &&
                          _vm.generalRoleItem[_vm.rolePropLabel]) ||
                        "",
                      readonly: "",
                      placeholder: "未选择角色",
                      "suffix-icon": "el-icon-arrow-down"
                    },
                    slot: "reference"
                  }),
                  _c(
                    "transition",
                    { attrs: { name: "fade-common" } },
                    [
                      _vm.showRoleSelect
                        ? _c("search-select-list", {
                            attrs: {
                              "list-items": _vm.generalRoleData,
                              "defalut-sel-id":
                                (_vm.generalRoleItem &&
                                  _vm.generalRoleItem[_vm.rolePropValue]) ||
                                ""
                            },
                            on: {
                              selected: data =>
                                _vm.handleSearchSelectSelectedCallback(-1, data)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "table-section pt-10" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "ts-body" },
              _vm._l(_vm.hotelCheckedItems, function(item, index) {
                return _c(
                  "div",
                  {
                    key: item.code,
                    staticClass: "ts-tr role-list-item display__flex"
                  },
                  [
                    _c("div", { staticClass: "ts-td" }, [
                      _c("div", { staticClass: "text-item" }, [
                        _c("span", [_vm._v(_vm._s(item.name))])
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "ts-td" },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              width: 220,
                              placement: "bottom",
                              "popper-class": "popper-select"
                            },
                            on: {
                              show: function($event) {
                                return _vm.handleRolePopoverShow(item.code)
                              }
                            },
                            model: {
                              value: item.showRoleSelect,
                              callback: function($$v) {
                                _vm.$set(item, "showRoleSelect", $$v)
                              },
                              expression: "item.showRoleSelect"
                            }
                          },
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "220px",
                                "line-height": "32px"
                              },
                              attrs: {
                                slot: "reference",
                                value: _vm.hotelRoleItemSelected(item)[
                                  _vm.rolePropLabel
                                ],
                                readonly: "",
                                placeholder: "未选择角色",
                                "suffix-icon": "el-icon-arrow-down"
                              },
                              slot: "reference"
                            }),
                            _c(
                              "transition",
                              { attrs: { name: "fade" } },
                              [
                                item.showRoleSelect
                                  ? _c("search-select-list", {
                                      attrs: {
                                        loading: _vm.isLoadingRoleList(
                                          item.code
                                        ),
                                        "list-items": _vm.enabledRoleList(
                                          item.code
                                        ),
                                        "defalut-sel-id": _vm.hotelRoleItemSelected(
                                          item
                                        )[_vm.rolePropValue]
                                      },
                                      on: {
                                        selected: data =>
                                          _vm.handleSearchSelectSelectedCallback(
                                            index,
                                            data
                                          )
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "remove-item",
                            on: {
                              click: function($event) {
                                return _vm.handleRemoveHotelRoleItem(index)
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/tag-close.png")
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "lz-modal__footer lz-modal__footer__line" },
      [
        _c(
          "el-button",
          {
            staticClass: "lz-modal__footer__button confirm",
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.handleSaveClick }
          },
          [_vm._v("确定")]
        ),
        _c(
          "el-button",
          {
            staticClass: "lz-modal__footer__button cancel",
            attrs: { size: "small" },
            on: { click: _vm.handleCloseClick }
          },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ts-head" }, [
      _c("div", { staticClass: "ts-tr" }, [
        _c("div", { staticClass: "ts-th" }, [_vm._v("已选中门店")]),
        _c("div", { staticClass: "ts-th" }, [_vm._v("角色")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }