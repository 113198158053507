<template>
  <div class="org-box page-box" v-loading="pageLoading">
    <lz-split v-model="splitValue" min="320" max="450">
      <template slot="left">
        <resource-pool class="child-box" @edit-member="handleEditMemberCallback"></resource-pool>
      </template>
      <template slot="right">
        <dept-member v-if="isPoolTypeDept" class="child-box node-detail" @edit="handleEditMemberCallback"></dept-member>
        <role-member v-else class="child-box node-detail" @edit="handleEditMemberCallback"></role-member>
      </template>
      <div slot="trigger" class="split-trigger__view">
        <i style="color: #ADB7C8; vertical-align: middle;" class="iconfont lzicon-dot_vertical"></i>
      </div>
    </lz-split>
    
    <!-- 成员新增/编辑组件 -->
    <member-edit-box
      v-if="showMEBox"
      v-model="showMEBox"
      :action="memberEditConfig.action"
      :data="memberEditConfig.data">
    </member-edit-box>
  </div>
</template>

<script>

import { mapState, mapGetters, mapMutations } from 'vuex'
import LzSplit from '_c/split'
import ResourcePool from './pool/pool.vue'
import DeptMember from './member/deptBox.vue'
import RoleMember from './member/roleBox.vue'
import MemberEditBox from './member/editBox.vue'

/**
 * 组织页面
 * @module @/view/organization
 */
export default {
  name: 'Organization',
  components: {
    LzSplit,
    ResourcePool,
    DeptMember,
    RoleMember,
    MemberEditBox
  },
  data () {
    return {
      // Split 组件 value
      splitValue: '320',

      // 成员新增/编辑
      showMEBox: false,
      memberEditConfig: {
        action: 1,
        data: null
      }
    }
  },
  computed: {
    ...mapState('org', [
      'pageLoading'
    ]),

    ...mapGetters('org', [
      'isPoolTypeDept'
    ]),
  },
  // 处理其他页面跳转“组织”页面逻辑
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      /**
       * 处理系统内跳转并进行特定事件操作（首页-快捷入口）
       */
      if (to.params.action) {
        vm.$store.commit('org/setPageAction', to.params.action);
      }

      /**
       * 处理外部系统跳转组织页面并定位特定视图逻辑，URL 携带查询参数“tab”（详情见 wiki "系统跳转" 记录）
       * - 进入组织页面，树视图定位到「组织架构」，示例：domain/#/organization?tab=1&from=pms
       * - 进入组织页面，树视图定位到「角色」，示例：domain/#/organization?tab=2&from=pms
       */
      let poolType;
      if (to.query && to.query.tab==2) {
        poolType = 'role';
      } else {
        poolType = 'dept';
      }
      vm.$store.commit('org/setPoolType', poolType);
    });
  },
  created () {
    this.setPageLoading(true);
  },
  methods: {
    ...mapMutations('org', [
      'setPageLoading'
    ]),

    /**
     * 新增/编辑成员回调
     */
    handleEditMemberCallback (action, data) {
      this.memberEditConfig.action = action;
      this.memberEditConfig.data = data;
      this.showMEBox = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .org-box {
    padding: 16px;
    position: relative;

    .child-box {
      background-color: #FFF;
      border-radius: 2px;
    }

    .node-detail {
      height: 100%;
      margin-left: 16px;
      overflow: hidden;
    }

    .split-trigger__view {
      color: #ADB7C8;
      width: 16px;
      height: 100px;
      line-height: 100px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: col-resize;
      user-select: none;
    }
  }
</style>
