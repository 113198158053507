var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lz-drawer__box" },
    [
      _c("div", { staticClass: "lz-drawer__body" }, [
        _c(
          "div",
          { staticClass: "form-section" },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "lz-form--block",
                attrs: {
                  model: _vm.formData,
                  rules: _vm.rules,
                  "label-width": "83px"
                }
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: _vm.$t("名称："), prop: "roleName" }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "50",
                                "show-word-limit": "",
                                placeholder: _vm.$t("请输入角色名称")
                              },
                              model: {
                                value: _vm.formData.roleName,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "roleName", $$v)
                                },
                                expression: "formData.roleName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.isRoleTypeHotel
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("绑定酒店："),
                                  prop: "hotelVid"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    value: _vm.formData.hotelName,
                                    disabled: true,
                                    readonly: "",
                                    placeholder: _vm.$t("请选择酒店")
                                  },
                                  on: { focus: _vm.handleSelectHotelClick }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            class: [
              "pt-20",
              "permission-section",
              { "multi-rows": _vm.isRoleTypeHotel }
            ]
          },
          [
            _c("select-permission", {
              ref: "selectPermission",
              attrs: {
                "role-type": _vm.formData.roleType,
                "is-role-type-hotel": _vm.isRoleTypeHotel,
                "is-action-copy": _vm.isActionCopy,
                hotelVid: _vm.formData.hotelVid
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "lz-drawer__footer lz-drawer__footer__line" },
        [
          _c(
            "el-button",
            {
              staticClass: "lz-drawer__footer__button confirm",
              attrs: { type: "primary" },
              on: { click: _vm.handleSaveClick }
            },
            [_vm._v(_vm._s(_vm.$t("确定")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "lz-drawer__footer__button cancel",
              on: {
                click: function($event) {
                  return _vm.handleCloseClick()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("取消")))]
          )
        ],
        1
      ),
      _c(
        "lz-modal",
        {
          attrs: {
            name: "select-hotel",
            width: "586px",
            title: _vm.modalConfig.title,
            "mask-closable": false,
            "loading-enable": "",
            loading: _vm.modalLoading,
            "footer-hide": ""
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { height: "580px" },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.showModal
                ? _c("select-hotel", {
                    attrs: {
                      refer: "role",
                      hotelVid: _vm.formData.hotelVid,
                      hotelName: _vm.formData.hotelName
                    },
                    on: {
                      loading: loading => {
                        _vm.modalLoading = loading
                      },
                      confirm: _vm.handleSelectHotelCallback
                    },
                    model: {
                      value: _vm.showModal,
                      callback: function($$v) {
                        _vm.showModal = $$v
                      },
                      expression: "showModal"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("lz-dialog", {
        attrs: {
          title: _vm.$t("是否退出编辑？"),
          content: _vm.$t("当前角色设置内容有变化，是否确定退出编辑？"),
          confirmButtonText: _vm.$t("继续编辑"),
          cancelButtonText: _vm.$t("仍然退出")
        },
        on: {
          confirm: function($event) {
            return _vm.handleDialogButtonClick("cancel")
          },
          cancel: function($event) {
            return _vm.handleDialogButtonClick("confirm")
          }
        },
        model: {
          value: _vm.showDialog,
          callback: function($$v) {
            _vm.showDialog = $$v
          },
          expression: "showDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }