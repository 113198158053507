var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c(
      "div",
      { staticClass: "modal-body lz-modal__body group-modal" },
      [
        _c("el-tree", {
          ref: "myTree",
          staticClass: "group-tree",
          attrs: {
            data: _vm.treeData,
            props: _vm.defaultProps,
            "node-key": "id",
            "show-checkbox": "",
            "check-strictly": "",
            "default-expand-all": ""
          },
          on: { "check-change": _vm.handleCheckChange }
        }),
        _c(
          "div",
          { staticClass: "lz-modal__footer lz-modal__footer__line" },
          [
            _c(
              "el-button",
              {
                staticClass: "lz-modal__footer__button confirm",
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.handleSaveClick }
              },
              [_vm._v("确定")]
            ),
            _c(
              "el-button",
              {
                staticClass: "lz-modal__footer__button cancel",
                attrs: { size: "small" },
                on: { click: _vm.handleCloseClick }
              },
              [_vm._v("取消")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }