var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal-box page-box" }, [
    _c(
      "div",
      { staticClass: "modal-body mt-10" },
      [
        _c(
          "search-list",
          {
            ref: "searchList",
            staticClass: "search-section",
            attrs: {
              placeholder: "搜索成员姓名/手机号",
              props: _vm.searchProps,
              showCheckbox: true,
              "search-list": _vm.searchList
            },
            on: {
              search: _vm.handleSearchCallback,
              cancel: _vm.handleSearchCancelCallback,
              "check-change": _vm.handleSearchCheckChangeCallback
            }
          },
          [
            _c("template", { slot: "widget" }, [
              _c("div", { staticClass: "filter-widget" }, [
                _c(
                  "div",
                  {
                    class: ["filter-item", { active: _vm.isFilterTypeAll }],
                    on: {
                      click: function($event) {
                        return _vm.handleFilterItemClick("all")
                      }
                    }
                  },
                  [_vm._v("所有成员")]
                ),
                _c(
                  "div",
                  {
                    class: ["filter-item", { active: !_vm.isFilterTypeAll }],
                    on: {
                      click: function($event) {
                        return _vm.handleFilterItemClick("part")
                      }
                    }
                  },
                  [_vm._v("已选成员(" + _vm._s(_vm.checkedValue.length) + ")")]
                )
              ])
            ])
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isSearchingState(),
                expression: "!isSearchingState()"
              }
            ],
            staticClass: "tree-section pt-15 pb-15"
          },
          [
            [
              _c("el-tree", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFilterTypeAll,
                    expression: "isFilterTypeAll"
                  }
                ],
                ref: "tree",
                staticClass: "tree-view",
                attrs: {
                  "node-key": "deptId",
                  "highlight-current": false,
                  "empty-text": "暂无数据",
                  data: _vm.treeData,
                  "default-expanded-keys": _vm.defaultExpandedKeys,
                  "render-content": _vm.renderTreeContent
                },
                on: { "node-expand": _vm.handleTreeNodeExpand }
              })
            ],
            !_vm.isFilterTypeAll
              ? [
                  _c(
                    "ul",
                    { staticClass: "check-list" },
                    [
                      _vm.checkedList.length > 0
                        ? _c(
                            "div",
                            { staticClass: "mb-5" },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  attrs: {
                                    value: _vm.checkAll,
                                    indeterminate: _vm.indeterminate
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.handleCheckAll.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "level-1" }, [
                                    _vm._v(_vm._s(_vm.activeRole.roleName))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.checkedList, function(item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "list-item" },
                          [
                            _c(
                              "el-checkbox",
                              {
                                on: {
                                  change: checked =>
                                    _vm.handleCheckboxChangeCallback(
                                      item,
                                      checked
                                    )
                                },
                                model: {
                                  value: item.checked,
                                  callback: function($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked"
                                }
                              },
                              [_c("span", [_vm._v(_vm._s(item.name))])]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "lz-modal__footer lz-modal__footer__line flex_shrink__0" },
      [
        _c(
          "el-button",
          {
            staticClass: "lz-modal__footer__button confirm",
            attrs: { type: "primary", size: "small" },
            on: { click: _vm.handleSaveClick }
          },
          [_vm._v("确定")]
        ),
        _c(
          "el-button",
          {
            staticClass: "lz-modal__footer__button cancel",
            attrs: { size: "small" },
            on: { click: _vm.handleCloseClick }
          },
          [_vm._v("取消")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }