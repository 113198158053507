<template>
<el-select :class="{'gorup-select-item': isGroupRole}" :value="roleIds" multiple :loading="loading" :no-data-text="$t('暂无可选角色')" @change="handleSelectChange" @focus="handleLoadOptions">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'OrgRoleSelect',

  inject: ['OrgAccount', 'OrgRole'],

  props: {
    roleIds: {
      type: Array,
      default: () => [],
    },
    hotelVid: {
      type: String, // 酒店vid 如果是酒店角色选择，需要传入酒店vid，否则为集团
      default: '0', // 默认集团会员
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: 'roleIds',
    event: 'update:role-ids',
  },

  data() {
    return {
      loading: true,
    }
  },
  computed: {
    isGroupRole() {
      return this.hotelVid == '0'
    },
  },
  methods: {
    handleSelectChange(val) {
      if (this.OrgRole) {
        if (this.isGroupRole) {
          this.OrgRole.handleGroupRoleUpdate(val)
        } else {
          //代码还需要完善，这里如果没有父子嵌套的话，就需要这样写，但是获取options的时，也需要这样的判断
        //   this.$emit('update:role-ids', val)
         this.OrgRole.handleHotelRoleUpdata(val, this.hotelVid)
        }
        // this.OrgRole.handleGroupRoleUpdate(val)
      } else {
        //代码还需要完善，这里如果没有父子嵌套的话，就需要这样写，但是获取options的时，也需要这样的判断
        this.$emit('update:role-ids', val)
      }
    },
    handleLoadOptions() {
      if (this.OrgRole && this.OrgRole.getRoleList) {
        this.loading = true
        this.OrgRole.getRoleList(this.hotelVid).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 360px;

  &.gorup-select-item {
    width: 100%;
  }
}
</style>
