import commonAPI from '@/view/common/api'

/**
 * Member edit account/mobile check mixin
 * TODO：账号/手机号全局唯一检查，2.0与1.x过渡逻辑，全量切换后删除
 */
export default {
  data () {
    return {
      accountError: '',
      mobileError: '',
      accountLoading: false,
      mobileLoading: false,
      saveHandle: false
    }
  },
  watch: {
    mobileLoading(val) {
      if (!val && this.saveHandle) {
        this.handleSaveClick()
      }
    }
  },
  methods: {
    /**
     * “账号” input blur 事件
     */
    handleAccountInputBlur (event) {
      this.accountError = '';
      this.accountLoading = false;
      let value = this.formData.accountUserName;
      if (value) {
        this.$refs['form'].validateField('accountUserName', errorMessage => {
          if (errorMessage == '') {
            this.checkAccount(value);
          }
        });
      }
    },

    /**
     * “手机号” input blur 事件
     */
    handleMobileInputBlur (event) {
      this.mobileError = false;
      this.mobileLoading = false;
      let value = this.formData.mobile;
      if (value) {
        this.$refs['form'].validateField('mobile', errorMessage => {
          if (errorMessage == '') {
            this.checkMobile(value);
          }
        });
      }
    },


    /**
     * 保存前检查
     * @note 此逻辑暂未使用
     */
    checkBeforeSave (account, mobile) {
      let requests = [];
      if (!this.isAccountUserNameDisabled && account) {
        let request1 = this.checkAccount(account);
        requests.push(request1);
      }
      if (mobile) {
        let request2 = this.checkMobile(mobile);
        requests.push(request2);
      }
      if (requests.length) {
        this.viewLoading(true);
        Promise.all(requests).then(resData => {
          if (resData.every(res => !res.data)) {
            this.saveMemberInfo();
          } else {
            this.viewLoading(false);
          }
        }).catch(error => {
          this.viewLoading(false);
          this.$notice.error(error.message);
        });
      } else {
        this.saveMemberInfo();
      }
    },

    
    /**
     * 检查账号是否存在
     * @param { String } accountName 账号
     */
    checkAccount (accountName) {
      let params = { name: accountName };
      let ft = commonAPI.checkAccountExists(params);
      this.accountLoading = true;
      ft.then(res => {
        // 防止接口速度慢，先后触发多个请求，造成逻辑干扰
        if (accountName == this.formData.accountUserName) {
          this.accountLoading = false;
          this.accountError = (res&&res.data) ? '账号已存在，请重新输入' : '';
          this.$refs['form'].validateField('accountUserName');
        }
      }).catch(error => {
        if (accountName == this.formData.accountUserName) {
          this.accountLoading = false;
        }
        console.error('Check account error: ', error.message);
      });
      return ft;
    },

    /**
     * 检查手机号是否存在
     * @param { String } mobile 手机号
     */
    checkMobile (mobile) {
      let params = { mobile };
      let ft = commonAPI.checkMobileExists(params);
      this.mobileLoading = true;
      ft.then(res => {
        // 防止接口速度慢，先后触发多个请求，造成逻辑干扰
        if (mobile == this.formData.mobile) {
          this.mobileLoading = false;
          if (res && res.data) {
            this.mobileError = '手机号已存在，请重新输入';
            this.$refs['form'].validateField('mobile');
          } else {
            this.mobileError = '';
          }
        }
      }).catch(error => {
        if (mobile == this.formData.mobile) {
          this.mobileLoading = false;
        }
        console.error('Check mobile error: ', error.message);
      });
      return ft;
    }
  }
}
