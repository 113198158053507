
let nodeIdSeed = 0;

export default class Node {
  constructor (options) {
    this.id = nodeIdSeed++;
    this.data = null;
    this.parent = null;
    this.isLeaf = false;
    this.level = 0;
    this.childNodes = [];
    
    for (const name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name];
      }
    }

    if (this.store) {
      this.store.registerNode(this);
    }

    if (this.data) {
      this.setData(this.data);
    }
  }

  get key () {
    return getNodeKey(this.data, this.store.categoryKey, this.store.hotelKey);
  }

  get disabled () {
    return !this.data.enabled;
  }

  setData (data) {
    let children;
    if (this.level===0 && Array.isArray(data)) {
      children = data;
    } else {
      children = (data.children||[]).concat(data.hotels||[]);
    }
    this.data = data;
    this.childNodes = [];
    if (!children.length) {
      this.isLeaf = true;
    }
    for (let i = 0; i < children.length; i++) {
      this.insertNode(children[i]);
    }
  }

  insertNode (data) {
    let child = { data, store: this.store };
    child.level = this.level + 1;
    let node = new Node(child);
    node.parent = this;
    this.childNodes.push(node);
  }
}


export const getNodeKey = (data, categoryKey, hotelKey) => {
  let key;
  if (data.hasOwnProperty('hotelName')) {
    key = hotelKey + data.hotelVid;
  } else if (data.hasOwnProperty('name')) {
    key = categoryKey + data.code;
  }
  return key;
}
