<template>
  <el-tree
    class="tree-view"
    :class="'row-h' + treeProps.rowHeight"
    ref="tree"
    :node-key="nodeKey"
    :expand-on-click-node="false"
    :highlight-current="false"
    :show-checkbox="showCheckbox"
    :check-strictly="checkStrictlyEnable"
    empty-text="暂无数据"
    :props="treeProps"
    :data="treeData"
    :default-expanded-keys="defaultExpandedKeys"
    :render-content="renderTreeContent"
    @current-change="handleTreeCurrentChange"
    @node-expand="handleTreeNodeExpand"
    @node-collapse="handleTreeNodeCollapse"
    @check="handleTreeNodeCheck">
  </el-tree>
</template>

<script>

// Element-UI tree default props
const TREE_PROPS_DEFAULT = {
  label: 'label',
  children: 'children',
  disabled: 'disabled',
  isLeaf: 'isLeaf',
  rowHeight: 30
}


/**
 * 树视图
 * @module @/view/organization
 * 
 * @note 勾选逻辑
 * - Check-A：父子勾选关联
 * - Check-B：父子勾选独立
 * - Check-C：勾父既勾子，勾子必勾父，去父既去子，去子不去父
 */
export default {
  name: 'TreeView',
  props: {
    // 自定义节点 title（Function(data)）
    customTitle: Function,

    // 事件操作按钮是否可用（Function(action))
    actionBtnEnable: Function,

    // 是否可编辑
    editable: {
      type: Boolean,
      default: false
    },

    // 是否可删除
    deletable: {
      type: Boolean,
      default: false
    },

    // 节点是否可单选
    showRadio: {
      type: Boolean,
      default: false
    },

    // 节点是否可勾选
    showCheckbox: {
      type: Boolean,
      default: false
    },

    // 是否遵循父子不互相关联勾选
    checkStrictly: {
      type: Boolean,
      default: false
    },

    // 节点 key
    nodeKey: {
      type: String,
      default: 'id'
    },

    // 配置属性
    props: Object
  },
  data () {
    return {
      treeData: null,
      // 默认展开节点 keys
      defaultExpandedKeys: [],
      // 重置 checkStrictly
      checkStrictlyReset: this.checkStrictly,

      // radio value
      radioValue: ''
    }
  },
  computed: {
    // tree 组件 props
    treeProps () {
      if (this.props) {
        return { ...TREE_PROPS_DEFAULT, ...this.props };
      } else {
        return TREE_PROPS_DEFAULT;
      }
    },

    // 获取组件引用
    treeRef () {
      return this.$refs['tree'];
    },

    // tree node checkStrictly 配置
    checkStrictlyEnable () {
      return this.checkStrictly && this.checkStrictlyReset;
    },

    // 编辑按钮是否可用
    editBtnEnable () {
      if (this.actionBtnDisable && typeof this.actionBtnDisable=='function') {
        return this.actionBtnEnable('edit');
      } else {
        return true;
      }
    },

    // 新增按钮是否可用
    addBtnEnable () {
      if (this.actionBtnDisable && typeof this.actionBtnDisable=='function') {
        return this.actionBtnEnable('add');
      } else {
        return true;
      }
    },
    
    // 删除按钮是否可用
    deleteBtnEnable () {
      if (this.actionBtnDisable && typeof this.actionBtnDisable=='function') {
        return this.actionBtnEnable('delete');
      } else {
        return true;
      }
    }
  },
  methods: {
    /**
     * 树视图渲染
     */
    renderTreeContent (h, { node, data, store }) {
      let rowElements = [];
      let prefixElments = [];
      if (this.showRadio) {
        prefixElments.push(
          h('el-radio', {
            props: {
              value: this.radioValue,
              label: data[this.nodeKey]
            },
            class: 'tree-radio ml-5'
          }, '')
        )
      } else {
        prefixElments.push(
          h('i', {
            class: `tree-folder__icon iconfont ${node.expanded ? 'lzicon-folder_open' : 'lzicon-folder'}`
          })
        )
      }
      prefixElments.push(
        // 节点标题
        h('span', {
          class: `tree-node__title ${node.isCurrent&&!this.showRadio&&!this.showCheckbox ? 'selected' : ''}`,
          attrs: {
            title: this.nodeTitle(data)
          }
        }, this.nodeTitle(data))
      );
      let that = this;
      rowElements.push(
        h('div', {
          class: 'tree-row__prefix display__flex align_items__center',
          on: {
            click: function () {
              if (that.showRadio) {
                const key = data[that.nodeKey];
                if (key !== that.radioValue) {
                  that.radioValue = key;
                  that.$emit('radio-change', data);
                }
              }
            }
          }
        }, prefixElments)
      );
      if (this.editable || this.deletable) {
        // 节点事件项
        let actionElements = [];
        if (this.editable) {
          actionElements.push(
            h('div', {
              style: this.actionBtnPermission('edit') ? '' : 'display: none;',
              class: 'tree-row__action__item',
              on: {
                click: (event) => {
                  // 节点编辑事件
                  this.handleEditTreeNode(data, node);
                  // 阻止事件冒泡
                  event.stopPropagation();
                }
              }
            }, [
              h('i', {
                class: 'iconfont lzicon-edit_outline'
              })
            ]),
            h('div', {
              style: this.actionBtnPermission('add') ? '' : 'display: none;',
              class: 'tree-row__action__item',
              on: {
                click: (event) => {
                  // 节点新增事件
                  this.handleAddTreeNode(data);
                  event.stopPropagation();
                }
              }
            }, [
              h('i', {
                class: 'iconfont lzicon-add_circle_outline'
              })
            ])
          );
        }
        if (this.deletable) {
          actionElements.push(
            h('div', {
              style: this.actionBtnPermission('delete') ? '' : 'display: none;',
              class: 'tree-row__action__item',
              on: {
                click: (event) => {
                  // 节点删除事件
                  this.handleDeleteTreeNode(data, node);
                  event.stopPropagation();
                }
              }
            }, [
              h('i', {
                class: 'iconfont lzicon-delete'
              })
            ])
          );
        }
        rowElements.push(
          h('div', {
            class: `tree-row__suffix ${node.hover ? '' : 'display-none'}`,
          }, actionElements)
        )
      }
      return h('div', {
        class: 'tree-row display__flex align_items__center justify_content__space_between',
        on: {
          mouseover: () => {
            this.$set(node, 'hover', true);
          },
          mouseout: () => {
            this.$set(node, 'hover', false);
          }
        }
      }, rowElements);
    },

    /**
     * 获取 tree node title
     * @param { Object } data 节点数据对象
     * @returns { String } node title
     */
    nodeTitle (data) {
      if (this.customTitle && typeof this.customTitle === 'function') {
        return this.customTitle(data);
      } else {
        return data[this.props.label];
      }
    },

    /**
     * 获取操作按钮是否有权限
     * @returns { Boolean } true/false
     */
    actionBtnPermission (action) {
      if (this.actionBtnEnable && typeof this.actionBtnEnable=='function') {
        return this.actionBtnEnable(action);
      } else {
        return true;
      }
    },

    /**
     * 设置 tree node 选中
     * @param { String|Number } key 节点 key
     */
    _setCurrentKey (key) {
      this.$nextTick(() => {
        this.treeRef.setCurrentKey(key);
      });
    },

    /**
     * 新增节点事件
     * @param { Object } data 节点对象数据
     */
    handleAddTreeNode (data) {
      this.$emit('add', data);
    },

    /**
     * 编辑节点事件
     * @param { Object } data 节点对象数据
     * @param { Object } node 节点对象
     */
    handleEditTreeNode (data, node) {
      const parentData = node.level!==1 ? node.parent.data : null;
      this.$emit('edit', data, parentData);
    },

    /**
     * 删除节点事件
     * @param { Object } data 节点对象数据
     * @param { Object } node 节点对象
     */
    handleDeleteTreeNode (data, node) {
      this.$emit('delete', data);
    },

    /**
     * 树节点选择事件
     * @param { Object } data 当前节点数据
     * @param { Object } node 当前节点对象
     */
    handleTreeCurrentChange (data, node) {
      this.$emit('current-change', data);
    },

    /**
     * 树节点展开事件
     */
    handleTreeNodeExpand (data, node, nodeEle) {
      const index = this.defaultExpandedKeys.findIndex(id => id === data[this.nodeKey]);
      if (index === -1) {
        this.defaultExpandedKeys.push(data[this.nodeKey]);
      }
    },

    /**
     * 树节点收起事件
     */
    handleTreeNodeCollapse (data, node, nodeEle) {
      const index = this.defaultExpandedKeys.findIndex(id => id === data[this.nodeKey]);
      if (index !== -1) {
        this.defaultExpandedKeys.splice(index, 1);
      }
    },

    /**
     * 树节点 check 事件
     */
    handleTreeNodeCheck (data, { checkedNodes, checkedKeys, halfCheckedNodes, halfCheckedKeys }) {
      this.$emit('check-change', checkedNodes);
    },


    /**
     * 检查是否存在父节点勾选
     * @param { Object } node 节点对象
     * @returns { Boolean } true/false
     */
    _checkIfParentNodeChecked (node) {
      if (node.level===1 || !node.parent.checked) {
        return false;
      } else if (node.parent.checked) {
        return true;
      }
    },


    /** 外部调用 **/
    /**
     * 设置组件数据
     * @param { Array } data 树视图数据
     * @param { Array } data 默认展开的节点 keys
     */
    setData (data, expandedKeys=[]) {
      // 默认展开一级及指定节点
      this.defaultExpandedKeys = data.map(item => item[this.nodeKey]);
      expandedKeys.forEach(key => {
        if (this.defaultExpandedKeys.findIndex(expandedKey => expandedKey == key) === -1) {
          this.defaultExpandedKeys.push(key);
        }
      });
      this.treeData = data;
    },

    /**
     * 更新组件数据
     * @param { Array } data 树视图数据
     */
    updateData (data) {
      this.treeData = data;
    },

    /**
     * 删除节点数据
     * @note 由于 tree 数据会刷新，这里无需主动删除节点
     * @param { String|Number } id node key
     */
    deleteData (id) {
      const index = this.defaultExpandedKeys.findIndex(key => key === id);
      if (index !== -1) {
        this.defaultExpandedKeys.splice(index, 1);
      }
    },

    /**
     * 设置组件节点选中
     * @param { String|Number } id node key
     */
    setCurrentKey (id) {
      this._setCurrentKey(id);
    },

    /**
     * 设置组件节点 radio 选中
     * @param { String|Number } id node key
     */
    setNodeSelected (id) {
      this.radioValue = id;
    },

    /**
     * 设置组件节点 checkbox 状态
     * @param { String|Number } id node key
     * @param { Boolean } value 勾选状态
     */
    setNodeCheckState (id, value) {
      this.treeRef.setChecked(id, value);
    },

    /**
     * 批量设置组件节点 checkbox 勾选
     * @param { Array } keys 勾选节点 key 集合
     */
    setNodesChecked (keys) {
      this.treeRef.setCheckedKeys(keys);
    },

    /**
     * 设置 tree 节点 checkbox 状态
     * @note 针对勾选逻辑处理（全选/取消全选）
     * - "Check-A"：设置 root node 勾选状态并同步 child node
     * - “Check-B”：临时重置 checkStricty 后，执行 “Check-A” 逻辑
     * @param { Boolean } value 勾选状态
     */
    setTreeCheckAllState (value) {
      if (this.checkStrictly) {
        this.checkStrictlyReset = false;
      }
      this.$nextTick(() => {
        this.treeData.forEach(item => {
          this.treeRef.setChecked(item[this.nodeKey], value, true);
        });
        this.$nextTick(() => {
          let checkedNodes = this.treeRef.getCheckedNodes();
          this.$emit('check-change', checkedNodes);

          this.checkStrictlyReset = this.checkStrictly;
        });
      });
    },

    /**
     * 获取勾选的节点数据
     * @note 对于“Check-C”逻辑，由于 tree 是展开后渲染，使用 getCheckedKeys 方法存在不能获取未渲染的 checked 节点情况，采用递归遍历数据源方案
     */
    getCheckedData () {
      return this.treeRef.getCheckedNodes();
    },

    /**
     * 获取顶层勾选状态的节点数据
     * @note 只返回顶层勾选状态节点，不包含半选状态节点
     */
    getTopCheckedData () {
      // 不包含半选状态
      const checkedKeys = this.treeRef.getCheckedKeys();
      let checkedList = [];
      checkedKeys.forEach(key => {
        const node = this.treeRef.getNode(key);
        if (!this._checkIfParentNodeChecked(node)) {
          checkedList.push({
            [this.nodeKey]: node.data[this.nodeKey],
            [this.props.label]: node.data[this.props.label]
          });
        }
      });
      return checkedList;
    }
  }
}
</script>

<style lang="scss" scoped src="./tree.scss"></style>
