<template>
<div class="role-container">
    <div class="role-left-wrapper">
      <!-- <el-collapse v-model="hotelCollapseActive" accordion @change="handleHotelCollapseChange">
        <el-collapse-item v-for="(item, index) in hotelList" :key="index" :title="item.hotelVid == '0' ? groupName : item.hotelName" :name="item.hotelVid">
          <template #title>
            <div class="role-unit-title">
              <el-tooltip v-if="`${item.hotelVid == '0' ? groupName : item.hotelName}`.length>9" :enterable='false' :content="item.hotelVid == '0' ? groupName : item.hotelName" placement="top">
                <p>{{ item.hotelVid == '0' ? groupName : item.hotelName }}</p>
              </el-tooltip>
              <p v-else>{{ item.hotelVid == '0' ? groupName : item.hotelName }}</p>
              <el-button type="text" size="mini" @click.prevent.stop="handleAdd(item)">新增</el-button>
            </div>
          </template>
          <div class="role-unit-content" v-if="item.roles && item.roles.length > 0 && item.loaded">
            <div
              class="role-item"
              :class="{active: role.value == activeRoleId}"
              v-for="role in item.roles"
              :key="`${item.hotelVid}-${role.value}`"
              :id="`${item.hotelVid}-${role.value}`"
              @click="handleRoleChange(role.value)"
            >
              <el-tooltip v-if="role.label && `${role.label}`.length>10" :enterable='false' :content="role.label" placement="top">
                <p>{{ role.label }}</p>
              </el-tooltip>
              <p v-else>{{ role.label }}</p>
              <el-button type="text" size="mini" @click.prevent.stop="handleEdit(role, item)">编辑</el-button>
            </div>
          </div>
          <div class="role-unit-content" v-else-if="item.roles && item.roles.length == 0 && item.loaded">
            <el-empty :image-size="88" description="暂无角色"></el-empty>
          </div>
        </el-collapse-item>
      </el-collapse> -->
      <roleCollapse :hotelList="hotelList" :groupName="groupName" :activeRoleId="activeRoleId" @toggle-content-show="handleToggleContentShow" @add="handleAdd" @toggle-active="handleRoleChange" @edit="handleEdit"></roleCollapse>
    </div>
    <div class="role-right-wrapper">
       <div class="role-right-title">
        {{getAllAccoutTitleLabel}}
       </div>
      <el-table :data="currentRoleMemberInfo.list">
        <el-table-column prop="userName" :label="$t('姓名')"></el-table-column>
        <el-table-column prop="userAccount" :label="$t('账号')"></el-table-column>
        <el-table-column prop="state" :label="$t('状态')">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state == 1" size="small" type="success" effect="plain">{{$t('启用')}}</el-tag>
            <el-tag v-else size="small" type="danger" effect="plain">{{$t('停用')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createUser" :label="$t('创建人')"></el-table-column>
        <el-table-column prop="createTime" :label="$t('创建时间')"></el-table-column>
        <el-table-column prop="operator" :label="$t('修改人')"></el-table-column>
        <el-table-column prop="updateTime" :label="$t('修改时间')"></el-table-column>
        <template #empty>
            <el-empty :description="$t('暂无数据')"></el-empty>
        </template>
      </el-table>
      <div class="page-container">
        <el-pagination layout="prev, pager, next, jumper" background @current-change="handleCurrentMemberPageChange" :current-page="currentRoleMemberInfo.pageIndex" :total="currentRoleMemberInfo.total" :page-size="currentRoleMemberInfo.pageSize"></el-pagination>
      </div>
    </div>
    <!-- <AddRole v-model:visible="addVisible" /> -->

    <!-- 新增/编辑 -->
    <lz-drawer v-model="showDrawer" name="role-edit" :width="1066" :loadingEnable="true" :loading="drawerLoading" :title="drawerConfig.title" :before-close="handleDrawerBeforeClose" @close="drawerLoading = false">
      <edit-view v-if="showDrawer" v-model="showDrawer" ref="editView" :action="drawerConfig.action" :id="drawerConfig.data.id" :hotel-data="drawerConfig.data.hotelData" @loading="
          (loading) => {
            drawerLoading = loading
          }
        " @action="handleEditDrawerCallback"></edit-view>
    </lz-drawer>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import _ from 'lodash';
import commonApi from '@/view/common/api';
import roleApi from '@/view/organization/api/role';
import memberAPI from '@/view/organization/api/member';

import EditView from '../role/edit.vue';
import roleCollapse from './roleCollapse/index.vue';
// import role from '../api/role'
// import AddRole from './add.vue'

export default {
  name: 'Role',
  components: {
    // AddRole,
    EditView,
    roleCollapse
  },
  data() {
    return {
      loading: false,
      hotelList: [],
      addVisible: false,
      hotelCollapseActive: '',
      activeRoleId: '',
      memberInfoObj: {},
      currentRoleMemberInfo: {
        id: '',
        list: [],
        total: 0,
        pageIndex: 1,
        pageSize: 10
      },

      drawerConfig: {
        title: '',
        // 操作类型（1-新增，2-编辑, 3-复制）
        action: 1,
        data: {}
      },
      showDrawer: false,
      drawerLoading: false
    };
  },
  watch: {
    activeRoleId(val) {
      this.getMemberList(val);
    }
  },
  computed: {
    groupName() {
      return this.$store.getters.userOrgName;
    },
    getAllAccoutTitleLabel() {
      const crtHotel = _.find(this.hotelList, (it) => it.hotelVid == this.hotelCollapseActive);
      if (crtHotel) {
        const crtRole = _.find(crtHotel.roles, (it) => it.value == this.activeRoleId);
        if (crtRole) {
          return intl.$t(`【{slot0} - {slot1}】的账号`, { "slot0": crtHotel.hotelName, "slot1": crtRole.label });
        } else return '';
      } else return '';

    }
  },
  methods: {
    getHotelList() {
      commonApi.hotelListByName().then((res) => {
        if (res.code == 200 && res.data) {
          // console.log(res.data)
          const rs = _.sortBy(_.clone(res.data), (it) => it.hotelVid);
          this.hotelCollapseActive = rs[0].hotelVid;
          this.hotelList = _.clone(rs).map((it, index) => {
            return {
              isCollapseShow: index == 0,
              isHotel: it.hotelVid != '0',
              hotelId: it.hotelId,
              hotelVid: it.hotelVid,
              hotelName: it.hotelName,
              loaded: false, //是否已经加载过子数据
              roles: []
            };
          });
          this.getRoleList(rs[0].hotelVid);
        } else {
          this.$notice.error(res.message);
        }
      });
    },

    getRoleList(hotelVid) {
      try {
        roleApi.
        roleHotel({
          hotelVid
        }).
        then((res) => {
          if (res.code == 200) {
            let aIdx = _.findIndex(this.hotelList, (it) => it.hotelVid == hotelVid);
            if (res.data && res.data.length > 0) {
              if (aIdx > -1) {
                this.hotelList[aIdx].roles = res.data.map((it) => {
                  return {
                    label: it.roleName,
                    value: it.id,
                    role: {
                      ...it,
                      isHotelRole: hotelVid != '0'
                    }
                  };
                });
                this.hotelList[aIdx].loaded = true;
                this.activeRoleId = `${res.data[0].id}`;
              }
            } else {
              if (aIdx > -1) {
                this.hotelList[aIdx].roles = [];
                this.hotelList[aIdx].loaded = true;
                this.activeRoleId = '';
              }
            }
          } else {
            console.error(intl.$t("获取角色失败"), res.message);
          }
        }).
        catch((err) => {
          console.error(intl.$t("获取角色失败"), err);
        });
      } catch (err) {
        console.error(intl.$t("获取角色失败"), err);
      }
    },
    // 获取角色id
    getMemberList(roleId, pageIndex = 1, pageSize = 10) {
      if (!roleId || roleId == '') {
        this.currentRoleMemberInfo = {
          list: [],
          total: 0,
          pageIndex: pageIndex,
          pageSize: pageSize
        };
        return;
      }
      const paramInfo = this.memberInfoObj[roleId] || {};
      if (paramInfo && paramInfo.pageIndex && paramInfo.pageIndex == pageIndex && paramInfo.list && paramInfo.list.length > 0) {
        this.currentRoleMemberInfo = {
          list: paramInfo.list || [],
          total: paramInfo.total || 0,
          pageIndex: paramInfo.pageIndex || pageIndex,
          pageSize: paramInfo.pageSize || pageSize
        };
        return;
      }
      memberAPI.
      memberListPage({
        roleId,
        pageIndex: pageIndex || paramInfo.pageIndex || 1,
        pageSize: pageSize || paramInfo.pageSize || 2,
        state: 1
      }).
      then((res) => {
        let rrs;
        if (res.code == 200 && res?.data?.datas && (res?.data?.datas || []).length > 0) {
          rrs = {
            list: res?.data?.datas || [],
            total: res?.data?.total || 0,
            pageIndex: res?.data?.currPage || pageIndex || paramInfo.pageIndex || 1,
            pageSize: pageSize || paramInfo.pageSize || 10
          };
        } else {
          rrs = {
            list: [],
            total: 0,
            pageIndex: pageIndex || paramInfo.pageIndex || 1,
            pageSize: pageSize || paramInfo.pageSize || 10
          };
        }
        this.memberInfoObj[`${roleId}`] = _.clone(rrs);
        this.currentRoleMemberInfo = _.clone(rrs);
      });
    },

    handleRoleChange(val, hotel) {
      this.activeRoleId = val;
      this.hotelCollapseActive = hotel.hotelVid;
    },

    /**
     * 节点是否是“酒店”
     */
    isNodeHotel(data) {
      return data.isHotel;
    },
    /**
     * 节点是否是“酒店角色”
     */
    isNodeHotelRole(data) {
      return data.isHotelRole;
    },

    handleAdd(data) {
      //   this.addVisible = true
      this.drawerConfig.title = intl.$t("新增角色");
      this.drawerConfig.action = 1;
      if (this.isNodeHotel(data)) {
        this.drawerConfig.data = {
          hotelData: { hotelVid: data.hotelVid, hotelName: data.hotelName }
        };
      } else {
        this.drawerConfig.data = {};
      }
      this.showDrawer = true;
    },
    handleEdit(data, hotelData) {
      this.drawerConfig.title = intl.$t("编辑角色");
      this.drawerConfig.action = 2;
      this.drawerConfig.data = {
        id: parseInt(data.role.id), //组件需要 number类型
        nodeData: _.clone(data.role)
      };
      if (this.isNodeHotelRole(_.clone(data.role))) {
        this.drawerConfig.data.hotelData = { hotelVid: hotelData.hotelVid, hotelName: hotelData.hotelName };
      }
      this.showDrawer = true;
    },
    handleHotelCollapseChange(val) {
      let aItem = _.find(this.hotelList, (it) => it.hotelVid == val);

      if (aItem && !aItem.loaded) {
        this.getRoleList(val);
      } else {
        if (aItem && aItem.roles && aItem.roles.length > 0) {
          this.activeRoleId = `${aItem.roles[0].value}`;
        } else {
          this.activeRoleId = '';
        }
      }
    },

    handleToggleContentShow(hotel, isShow) {
      if (isShow) {
        this.handleHotelCollapseChange(hotel.hotelVid);
        this.hotelCollapseActive = hotel.hotelVid;
      }
      let aIdx = _.findIndex(this.hotelList, (it) => it.hotelVid == hotel.hotelVid);
      if (aIdx > -1) {
        this.hotelList[aIdx].isCollapseShow = isShow;
      }
    },

    handleEditDrawerCallback(arg0, arg1) {
      // this.getMemberList(this.activeRoleId)
      this.getRoleList(this.hotelCollapseActive);
      //   console.log('handleEditDrawerCallback', arg0, arg1)
    },

    handleDrawerBeforeClose(done) {
      // console.log('handleDrawerBeforeClose',arg0)
      if (this.$refs['editView'].checkBeforeClose()) {
        done();
      }
    },

    handleCurrentMemberPageChange(pageIndex) {
      this.getMemberList(this.activeRoleId, pageIndex);
    }
  },
  mounted() {
    this.getHotelList();
  }
};
</script>

<style lang="scss" scoped>
.role-container {
  display: flex;
  .role-left-wrapper {
    width: 240px;
    min-height: 400px;
    background-color: #fff;
    padding: 10px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      right: -5px;
      width: 1px;
      height: calc(100% + 40px);
      background-color: #E5E8F0;
    }
    :deep(.el-collapse){
      border: none !important;
    }
    :deep(.el-collapse-item__header){
      position: relative;
      border:none !important;
    }
    :deep(.el-collapse-item__arrow) {
      position: absolute;
      left: 0;
      top: 16px;
      font-size: 16px;
    }
    :deep(.el-icon-arrow-right:before){
      content: "\e791";
    }
    :deep(.el-collapse-item__wrap){
      border:none !important;
    }
  }
  .role-right-wrapper {
    margin-left: 8px;
    flex: 1;
    background-color: #fff;
    overflow: hidden;
    padding: 10px;
    .role-right-title {
      font-weight: 500;
      font-size: 16px;
      color: #283145;
      height: 22px;
      line-height: 22px;
      padding: 16px 0 12px 16px;
      box-sizing: content-box;
      position: relative;
      margin-bottom: 15px;
      &::before {
        content: '';
        position: absolute;
        top: 20px;
        left: 0;
        width: 4px;
        height: 14px;
        background: #497CF6;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 4px;
        width: 100%;
        height: 1px;
        background: #E5E8F0;
      }
    }

    :deep(.el-table th.el-table__cell > .cell) {
      padding-left: 14px !important;
    }
    :deep(.el-table .el-table__cell) {
      padding: 6px 0 !important;
    }
    :deep(.el-table td.el-table__cell),
    :deep(.el-table th.el-table__cell.is-leaf) {
      border-color: #E5E8F0 !important;
    }
    :deep(.el-table--fit) {
      border-color: #E5E8F0 !important;
    }
    :deep(.el-table th.el-table__cell) {
      background-color: #F5F7FD !important;
    }
    :deep(.el-table .el-table__cell) {
      font-weight: 400;
      font-size: 14px;
      color: #575B6B;
    }
    :deep(.el-table th.el-table__cell > .cell){
      font-weight: 500;
      font-size: 14px;
      color: #283145;
    }
  }
  .role-unit-title {
    flex: 1;
    display: flex;
    & > p {
      flex: 1;
      padding-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 194px;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 16px;
      color: #283145;
    }
  }
  :deep(.el-collapse-item__content) {
    padding-bottom: 10px;
  }
  .role-unit-content {
    .role-item {
      padding-left: 10px;
      height: 36px;
      line-height: 36px;
      display: flex;
      cursor: pointer;
      & > p {
        flex: 1;
        padding-left: 14px;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 182px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 14px;
        color: #575B6B;
      }
      &.active,
      &:hover {
        &>p{
          // background-color: #F4F8FD;
          color: #4A7CF6;
        }
      }
    }
  }

  .page-container {
    padding-top: 16px;
    text-align: right;
  }
}
</style>
