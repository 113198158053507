import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

// 部门 tree
const deptTree = ajax.post(config.apiConf.serviceApi + 'deptInfo/tree');
// 部门保存
const deptSave = ajax.post(config.apiConf.serviceApi + 'deptInfo/save');
// 部门删除
const deptDelete = ajax.post(config.apiConf.serviceApi + 'deptInfo/delete');
// 部门搜索
const deptSearch = ajax.post(config.apiConf.serviceApi + 'deptInfo/listByFuzzyName');

/**
 * 部门相关 api
 */
export default {
  deptTree,
  deptSave,
  deptDelete,
  deptSearch
}
