<template>
  <div>
    <member-box
      ref="memberBox"
      refer="dept"
      :title="activeDeptName"
      :loading="boxLoading"
      @page-change="handlePaginationOnChangeCallback"
      @edit="hanleTableItemEditCallback">
      <div class="action-box" slot="action">
        <div class="select-view">
          <el-select :value="filterForm.state">
            <el-option
              v-for="item in stateEnum"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @click.native="handleStateOptionClick(item.value)">
            </el-option>
          </el-select>
        </div>
        <div class="button-view">
          <div
            v-if="isMemberAddPermission"
            class="action-button__icon--basic action-button"
            :class="{ disabled: isActionDisabled }"
            @click="handleAddClick">
            <i class="el-icon-plus prefix-icon"></i>
            <span class="suffix-text">新增成员</span>
          </div>
          <div
            v-if="isMemberDeptEditPermission"
            class="action-button__icon--basic action-button"
            :class="{ disabled: isActionDisabled }"
            @click="handleAdjustDeptClick">
            <span class="suffix-text">更换部门</span>
          </div>
          <div
            v-if="isMemberDeletePermission"
            class="action-button__icon--basic action-button red"
            :class="{ disabled: isActionDisabled }"
            @click="handleMemberDeleteClick">
            <span class="suffix-text">删除成员</span>
          </div>
        </div>
      </div>
    </member-box>

    <!-- 调整部门 -->
    <lz-modal
      v-model="showModal"
      name="change-dept"
      width="586px"
      :mask-closable="false"
      loading-enable
      :loading="modalLoading"
      footer-hide>
      <div slot="header" class="lz-modal__header">
        <span class="mr-5">{{ modalConfig.title }}</span>
        <el-popover
          :width="350"
          trigger="hover"
          placement="bottom">
          <div slot="reference" style="display: inline-block; line-height: 1; cursor: help;">
            <lz-icon style="vertical-align: top; margin-top: 2px;" type="iconfont" name="lzicon-question_circle_outline" color="#474F64" :size="12"></lz-icon>
          </div>
          <div>更换部门会将原有用户的所属部门清空，更换为新的所属部门。</div>
        </el-popover>
      </div>
      <div slot="body" style="height: 580px;">
        <select-dept
          v-if="showModal"
          v-model="showModal"
          :show-checkbox="true"
          :check-strictly="true"
          @loading="(loading) => { modalLoading = loading }"
          @confirm="handleSelectDeptCallback">
        </select-dept>
      </div>
    </lz-modal>

    <!-- 删除成员 -->
    <lz-dialog
      v-model="showDialog"
      name="delete-member"
      width="360px"
      :title="dialogConfig.title"
      confirm-button-text="确认"
      :mask-closable="!dialogLoading"
      loading-enable
      :loading="dialogLoading"
      @confirm="handleDialogConfirmCallback">
    </lz-dialog>
  </div>
</template>

<script>

import { mapState, mapGetters, mapMutations } from "vuex"
import MemberBox from './box.vue'
import SelectDept from '../dept/selectDept.vue'
import memberAPI from '../api/member'

/**
 * 部门成员列表
 * @module @/view/organization
 */
export default {
  name: "DeptListBox",
  components: {
    MemberBox,
    SelectDept
  },
  data () {
    return {
      boxLoading: false,
      modalLoading: false,
      // 更换部门
      showModal: false,
      modalConfig: {
        title: '更换部门',
        data: null
      },
      dialogLoading: false,
      // 删除成员弹窗
      showDialog: false,
      dialogConfig: {
        title: '是否确认删除？',
        data: null
      },
      
      filterForm: {
        // 状态
        state: 1
      },

      // 状态枚举数据源
      stateEnum: [
        { value: -1, label: '查看全部' },
        { value: 1, label: '只看启用成员' },
        { value: 0, label: '只看停用成员' }
      ]
    }
  },
  computed: {
    ...mapState('org', [
      'activeDept',
      'pageLoading'
    ]),

    ...mapGetters([
      'userInfo',
      'pageButtonPermission'
    ]),

    ...mapGetters('org', [
      'activeDeptId'
    ]),

    // 当前选中的部门名称
    activeDeptName () {
      return this.activeDept ? this.activeDept.deptName : '';
    },

    // 是否具有成员新增权限
    isMemberAddPermission () {
      return this.pageButtonPermission('hrms/organization', 'addUserButton');
    },

    // 是否具有成员部门调整权限
    isMemberDeptEditPermission () {
      return this.pageButtonPermission('hrms/organization', 'editUserDeptButton');
    },

    // 是否具有成员删除权限
    isMemberDeletePermission () {
      return this.pageButtonPermission('hrms/organization', 'allDeleteUserButton');
    },

    // 事件按钮是否 disabled
    isActionDisabled () {
      return this.activeDeptId===-1 || this.activeDeptId==='';
    },

    // 获取组件引用
    memberBoxRef () {
      return this.$refs['memberBox'];
    }
  },
  watch: {
    // 监听当前选择部门变更
    activeDeptId (newVal, oldVal) {
      this.memberBoxRef.resetView();
      if (newVal !== '') {
        this.getMemberListPage();
      }
    }
  },
  mounted () {
    if (!this.isActionDisabled) {
      this.getMemberListPage();
    }

    this.setMemberPageAction(this.$store.state.org.pageAction);
  },
  methods: {
    ...mapMutations('org', [
      'setPageLoading',
      'setPageAction'
    ]),

    /**
     * 处理页面事件
     * @note 新建成员（首页-快捷入口）
     * @param { String } action 操作事件
     */
    setMemberPageAction (action) {
      if (action === 'create-user') {
        this.handleAddClick(false);
        this.setPageAction('');
      }
    },

    /**
     * 状态选项点击事件
     * @note 使用 click 事件以捕获当前“选中项”点击事件
     * @param { Number } value 当前点击选项值
     */
    handleStateOptionClick (value) {
      if (this.isActionDisabled) {
        return;
      }
      
      this.memberBoxRef.resetView();
      if (value !== this.filterForm.state) {
        // Select 下拉项非重复点击，重新获取数据
        this.filterForm.state = value;
        this.getMemberListPage();
      } else {
        // Select 下拉项重复点击，刷新当前页
        this.getMemberListPage();
      }
    },


    /** 调整部门相关 **/
    /**
     * 调整成员部门
     * @param { Array } userData 勾选的成员
     * @param { Array } deptData 勾选的部门
     */
    updateDept (userData, deptData) {
      const params = {
        userIds: userData,
        deptIds: deptData
      };
      this.modalLoading = true;
      memberAPI.memberDeptUpdate(params).then(res => {
        this.modalLoading = false;
        if (res.code == 200) {
          this.$notice.success('更换部门成功');

          this.processAfterUpdate();
        } else {
          this.$notice.error(res.message);
        }
      }).catch(error => {
        this.modalLoading = false;
        this.$notice.error('系统异常，请稍后再试');
        console.error('Update member dept error: ', error.message);
      });
    },

    /**
     * 调整成员部门成功后处理逻辑
     * @note 
     * - 调整部门会影响分页数据获取，需要刷新数据列表
     */
    processAfterUpdate () {
      this.showModal = false;
      this.memberBoxRef.resetView();
      this.getMemberListPage();
    },

    /**
     * 调整部门点击事件
     */
    handleAdjustDeptClick () {
      if (!this.isActionDisabled) {
        const data = this.memberBoxRef.getSelectedData();
        if (!data.length) {
          this.$notice.error('请选择要修改的成员');
          return;
        }
        this.modalConfig.data = data.map(item => item.userId);
        this.showModal = true;
      }
    },

    /**
     * 选择部门成功回调
     * @param { Array } data 新部门 id 信息
     */
    handleSelectDeptCallback (data) {
      this.updateDept(this.modalConfig.data, data.map(item => item.id));
    },


    /** 删除相关 **/
    /**
     * 删除成员
     */
    deleteMember (data) {
      const params = { ids: data };
      this.dialogLoading = true;
      memberAPI.memberDelete(params).then(res => {
        this.dialogLoading = false;
        if (res.code == 200) {
          this.$notice.success('删除成员成功！');

          this.processAfterBatchDelete();
        } else {
          this.$notice.error(res.message);
        }
      }).catch(error => {
        this.dialogLoading = false;
        this.$notice.error('系统异常，请稍后再试');
        console.error('Delete dept member error: ', error.message);
      });
    },

    /**
     * 删除成员成功后处理逻辑
     * @note 删除事件可由列表和编辑视图触发
     * @note 
     * - 删除数据会影响分页数据获取，需要刷新数据列表
     */
    processAfterBatchDelete () {
      this.showDialog = false;
      this.memberBoxRef.resetView();
      this.getMemberListPage();
    },

    /**
     * 删除成员点击事件
     */
    handleMemberDeleteClick () {
      if (!this.isActionDisabled) {
        const data = this.memberBoxRef.getSelectedData();
        if (!data.length) {
          this.$notice.error('请选择要删除的成员');
          return;
        }
        const userIds = data.map(item => item.userId);
        if (userIds.findIndex(id => id == this.userInfo.userId) !== -1) {
          this.$notice.error('您不能删除自己，请重新选择成员');
        } else {
          this.dialogConfig.data = userIds;
          this.showDialog = true;
        }
      }
    },

    /**
     * 删除成员确认回调事件
     */
    handleDialogConfirmCallback () {
      this.deleteMember(this.dialogConfig.data);
    },


    /**
     * 分页事件回调
     */
    handlePaginationOnChangeCallback () {
      this.getMemberListPage();
    },

    /**
     * 获取部门成员列表（分页）
     */
    getMemberListPage () {
      const params = this.processFilterData();
      if (!this.pageLoading) {
        this.boxLoading = true;
      }
      memberAPI.memberListPage(params).then(res => {
        if (this.pageLoading) {
          this.setPageLoading(false);
        } else {
          this.boxLoading = false;
        }
        const memberBoxRef = this.$refs['memberBox'];
        if (res.code==200 && res.data && memberBoxRef) {
          memberBoxRef.setData(res.data);
        }
      }).catch(error => {
        if (this.pageLoading) {
          this.setPageLoading(false);
        } else {
          this.boxLoading = false;
        }
        this.$notice.error('系统异常，请稍后再试');
        console.error('Get dept member list error: ', error.message);
      });
    },

    /**
     * 处理筛选数据
     */
    processFilterData () {
      const pageData = this.memberBoxRef.getPageData();
      return {
        deptId: this.activeDeptId,
        state: this.filterForm.state!==-1 ? this.filterForm.state : '',
        includeChildDept: 1,
        pageIndex: pageData.currentPage,
        pageSize: pageData.pageSize
      }
    },


    /** 成员新增/编辑相关 **/
    /**
     * 新增成员点击事件
     * @param { Boolean } actionBlock 是否开启 action disable 阻断
     */
    handleAddClick (actionBlock=true) {
      if (!actionBlock || !this.isActionDisabled) {
        this.$emit('edit', 1);
      }
    },

    /**
     * “编辑”事件回调
     * @param { Object } data 数据项
     */
    hanleTableItemEditCallback (data) {
      this.$emit('edit', 2, { id: data.userId, name: data.userName });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .select-view {
    width: 140px;
    float: left;
  }

  .button-view {
    float: right;

    .action-button + .action-button {
      margin-left: 8px;
    }

    .action-button.red {
      color: $error-color;
      border-color: $error-color;
    }

    .action-button.disabled {
      cursor: not-allowed;
    }
  }
</style>
