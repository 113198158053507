<template>
  <div class="lz-drawer__box">
    <div class="lz-drawer__body">
      <!-- Form 视图 -->
      <div class="form-section">
        <el-form
          ref="form"
          class="lz-form--block"
          :model="formData"
          :rules="rules"
          label-width="83px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="名称：" prop="roleName">
                <el-input v-model="formData.roleName" maxlength="8" placeholder="请输入角色名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="角色类型：" prop="roleType">
                <el-select
                  v-model="formData.roleType"
                  :disabled="isActionEdit"
                  placeholder="请选择角色类型"
                  @change="handleRoleTypeSelectChange">
                  <el-option
                    v-for="item in roleTypeEnum"
                    :key="item.roleType"
                    :label="item.typeName"
                    :value="item.roleType">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="isRoleTypeHotel" :span="8">
              <el-form-item label="绑定酒店：" prop="hotelVid">
                <el-input
                  :value="formData.hotelName"
                  :disabled="isActionEdit"
                  readonly
                  placeholder="请选择酒店"
                  @focus="handleSelectHotelClick">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="角色级别：" prop="roleLevel">
                <el-select v-model="formData.roleLevel" placeholder="请选择角色级别">
                  <el-option
                    v-for="item in roleLevelEnum"
                    :key="item.roleLevel"
                    :label="item.levelName"
                    :value="item.roleLevel">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- 系统权限选择 -->
      <div :class="['pt-20', 'permission-section', { 'multi-rows': isRoleTypeHotel }]">
        <select-permission
          ref="selectPermission"
          :role-type="formData.roleType"
          :is-role-type-hotel="isRoleTypeHotel"
          :is-action-copy="isActionCopy"
          :hotelVid="formData.hotelVid">
        </select-permission>
      </div>
    </div>

    <div class="lz-drawer__footer lz-drawer__footer__line">
      <el-button v-if="isRoleSavePermission" type="primary" class="lz-drawer__footer__button confirm" @click="handleSaveClick">确定</el-button>
      <el-button class="lz-drawer__footer__button cancel" @click="handleCloseClick()">取消</el-button>
    </div>

    <!-- 绑定酒店 -->
    <lz-modal
      v-model="showModal"
      name="select-hotel"
      width="586px"
      :title="modalConfig.title"
      :mask-closable="false"
      loading-enable
      :loading="modalLoading"
      footer-hide>
      <div slot="body" style="height: 580px;">
        <select-hotel
          v-if="showModal"
          v-model="showModal"
          refer="role"
          :hotelVid="formData.hotelVid"
          :hotelName="formData.hotelName"
          @loading="(loading) => { modalLoading = loading }"
          @confirm="handleSelectHotelCallback">
        </select-hotel>
      </div>
    </lz-modal>

    <!-- 退出提示视图 -->
    <lz-dialog
      v-model="showDialog"
      title="是否退出编辑？"
      content="当前角色设置内容有变化，是否确定退出编辑？"
      confirmButtonText="继续编辑"
      cancelButtonText="仍然退出"
      @confirm="handleDialogButtonClick('cancel')"
      @cancel="handleDialogButtonClick('confirm')">
    </lz-dialog>
  </div>
</template>

<script>

// “角色类型”常量
const ROLE_TYPE_GENERAL = 'GENERAL_ROLE';
const ROLE_TYPE_HOTEL = 'HOTEL_ROLE';

import { mapGetters } from 'vuex'
import fetchMixin from './mixins/fetch'
import SelectPermission from './permission/index.vue'
import SelectHotel from '../common/selectHotel.vue'

/**
 * 角色新增/编辑
 * @module @/view/organization
 */
export default {
  name: 'RoleEdit',
  mixins: [ fetchMixin ],
  components: {
    SelectPermission,
    SelectHotel
  },
  props: {
    // 操作类型（1-新增，2-编辑, 3-复制）
    action: {
      type: Number,
      default: 1
    },

    // 角色 id
    id: Number,

    // 酒店角色对应的酒店数据
    hotelData: Object
  },
  data () {
    return {
      formData: {
        roleId: -1,
        roleName: '',
        roleLevel: '',
        roleType: '',
        hotelVid: '',
        hotelName: ''
      },
      // 原始角色数据
      originalData: null,

      rules: {
        roleName: [
          { required: true, whitespace: true, message: '请输入角色名称', trigger: 'blur' },
          { type: 'string', max: 8, message: '角色名称不能超过8位', trigger: 'click' }
        ],

        roleLevel: [
          { required: true, type: 'number', message: '请选择角色级别', trigger: 'change' }
        ],

        roleType: [
          { required: true, type: 'string', message: '请选择角色类型', trigger: 'change' }
        ],

        hotelVid: [
          { required: true, trigger: 'change', validator: (rule, value, callback) => {
            if (rule.required && (!value || value==0)) {
              callback(new Error('请选择酒店'));
            } else {
              callback();
            }
          }}
        ]
      },

      // “角色类型”数据源
      roleTypeEnum: [],
      // “角色等级”数据源
      roleLevelEnum: [],

      // 项目数据源
      projectData: [],

      // Modal 配置
      modalLoading: false,
      showModal: false,
      modalConfig: {
        title: ''
      },
      showDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'pageButtonPermission',
      'isTenantTypeBlocUser'
    ]),

    // 是否是“新增”操作
    isActionAdd () {
      return this.action === 1;
    },

    // 是否是“编辑”操作
    isActionEdit () {
      return this.action === 2;
    },

    // 是否是“复制”操作
    isActionCopy () {
      return this.action === 3;
    },

    // 是否“门店角色”可用
    isRoleTypeHotelEnable () {
      return this.isTenantTypeBlocUser;
    },

    // 当前角色类型是否是“酒店角色”
    isRoleTypeHotel () {
      return this.formData.roleType == ROLE_TYPE_HOTEL;
    },

    // 获取组件引用
    selectPermissionRef () {
      return this.$refs['selectPermission'];
    },

    // 是否具有角色“保存”权限
    isRoleSavePermission () {
      return this.pageButtonPermission('hrms/organization', 'saveRoleInfoButton');
    },
  },
  created () {
    this.initData();
  },
  methods: {
    initData () {
      if (this.hotelData) {
        this.formData.roleType = ROLE_TYPE_HOTEL;
        this.formData.hotelVid = this.hotelData.hotelVid;
        this.formData.hotelName = this.hotelData.hotelName;
      } else {
        this.formData.roleType = ROLE_TYPE_GENERAL;
      }

      this.getRoleTypeSelectList();
      this.getRoleLevelSelectList();
      this.getRoleInfo();
    },

    /**
     * 设置视图 loading 状态
     */
    viewLoading (loading) {
      this.$emit('loading', loading);
    },

    /**
     * “角色类型”下拉改变事件
     */
    handleRoleTypeSelectChange () {
      this.selectPermissionRef.roleTypeChange();
    },


    /** 绑定酒店相关 **/
    /**
     * “绑定酒店”点击事件
     */
    handleSelectHotelClick () {
      this.modalConfig.title = '分配酒店';
      this.showModal = true;
    },

    /**
     * “绑定酒店”选择回调事件
     * @param { Object } data 选择的数据对象
     */
    handleSelectHotelCallback (data) {
      this.showModal = false;
      if (data.hotelVid != this.formData.hotelVid) {
        this.formData.hotelVid = data.hotelVid;
        this.formData.hotelName = data.hotelName.trim();

        this.selectPermissionRef.selectHotelChange();
      }
    },


    /** footer 操作按钮相关 **/
    /**
     * Dialog 按钮操作事件
     */
    handleDialogButtonClick (action) {
      if (action === 'confirm') {
        this.handleCloseClick(true);
      } else {
        this.showDialog = false;
      }
    },

    /**
     * 保存点击事件
     */
    handleSaveClick () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          const { ppData, restIds } = this.selectPermissionRef.getData();
          if (!ppData.length) {
            this.$notice.error('请设置角色权限');
          } else {
            this.saveRole(ppData, restIds);
          }
        }
      });
    },

    /**
     * 关闭抽屉事件
     * @param { Boolean } checked 是否已检查
     */
    handleCloseClick (checked=false) {
      if (checked || this.checkBeforeClose()) {
        this.$emit('input', false);
      }
    },

    /**
     * 关闭前校验
     * @returns { Boolean } true/false
     */
    checkBeforeClose () {
      const result = this.checkIfEdit();
      if (result) {
        this.showDialog = true;
      }
      return !result;
    },

    /**
     * 校验是否产生编辑行为
     * @note 系统权限只判断了是否产生编辑行为而非产生实际修改
     * @returns { Boolean } true/false
     */
    checkIfEdit () {
      if (this.selectPermissionRef.checkIfTreeEdit()) {
        return true;
      }
      if (!this.isActionAdd) {
        return this.originalData 
               && (this.formData.roleName!=this.originalData.roleName 
               || this.formData.roleType!=this.originalData.roleType 
               || this.formData.roleLevel!=this.originalData.roleLevel);
      } else {
        return this.formData.roleName 
               || this.formData.roleLevel 
               || this.formData.roleType===(this.hotelData ? ROLE_TYPE_GENERAL : ROLE_TYPE_HOTEL)
               || (this.isRoleTypeHotel && this.formData.hotelVid!=this.hotelData.hotelVid);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/style/const";

  .form-section {
    border-bottom: 1px solid $border-color;
  }

  .permission-section {
    height: calc(100% - 55px);

    &.multi-rows {
      height: calc(100% - 109px);
    }
  }
</style>
