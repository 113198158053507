var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-list" }, [
    _c("div", { staticClass: "search-view display__flex" }, [
      _c(
        "div",
        { staticClass: "search-view__input flex_grow__1" },
        [
          _c("lz-icon", {
            attrs: {
              type: "iconfont",
              name: "lzicon-search_outline icon",
              size: 16
            },
            nativeOn: {
              click: function($event) {
                return _vm.handleSearchClick.apply(null, arguments)
              }
            }
          }),
          _c("el-input", {
            staticClass: "search-input",
            attrs: { placeholder: _vm.placeholder, clearable: "" },
            on: { clear: _vm.handleSearchInputClear },
            nativeOn: {
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.handleSearchClick.apply(null, arguments)
              }
            },
            model: {
              value: _vm.keyword,
              callback: function($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword"
            }
          })
        ],
        1
      ),
      _vm.customWidget
        ? _c(
            "div",
            { staticClass: "search-view__widget flex_shrink__0" },
            [_vm._t("widget")],
            2
          )
        : _vm._e()
    ]),
    _vm.isSearching
      ? _c(
          "div",
          {
            staticClass: "list-view mb-15",
            class: { "mt-15": !_vm.shortcutsEnable }
          },
          [
            _vm.shortcutsEnable
              ? _c(
                  "div",
                  { staticClass: "action-view" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "action-item",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleCheckAllClick(true)
                          }
                        }
                      },
                      [_vm._v("选择全部")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "action-item",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.handleCheckAllClick(false)
                          }
                        }
                      },
                      [_vm._v("取消选中")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.searchList && _vm.searchList.length > 0
              ? [
                  _c(
                    "ul",
                    _vm._l(_vm.searchList, function(item) {
                      return _c(
                        "li",
                        {
                          key: item[_vm.props.value],
                          staticClass:
                            "list-item pl-5 display__flex align_items__center"
                        },
                        [
                          _vm.showRadio
                            ? _c(
                                "el-radio",
                                {
                                  staticClass: "list-item-radio",
                                  attrs: {
                                    value: _vm.selectedValue,
                                    label: item[_vm.props.value]
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.handleRadioChangeCallback(item)
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item[_vm.props.label]))
                                  ])
                                ]
                              )
                            : _vm.showCheckbox
                            ? _c(
                                "el-checkbox",
                                {
                                  staticClass: "list-item-checkbox",
                                  attrs: {
                                    value: _vm.isCheckboxChecked(
                                      item[_vm.props.value]
                                    )
                                  },
                                  on: {
                                    change: checked =>
                                      _vm.handleCheckboxChangeCallback(
                                        item,
                                        checked
                                      )
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(item[_vm.props.label]))
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ]
              : _vm._e(),
            _vm.searchList && _vm.searchList.length === 0
              ? [_vm._m(0)]
              : _vm._e()
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-data-box" }, [
      _c("img", { attrs: { src: require("@/assets/images/no-data.png") } }),
      _c("div", { staticClass: "text" }, [_vm._v("没有查询到你想要的结果呢～")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }