
/**
 * Role edit permission tree mixin
 * @note tree check:
 * - 勾父既勾子
 * - 勾子必勾父
 * - 去父既去子
 * - 去子不去父
 */
export default {
  methods: {
    /**
     * 节点展开/收起点击事件
     * @param { Object } node 节点对象
     */
    handleNodeExpandClick (node) {
      if (node.isLeaf) return
      if (node.expanded) {
        node.collapse();
      } else {
        node.expand();
      }
    },

    /**
     * 节点 check 事件
     */
    handleNodeCheckChange (checked, node) {
      // 向下递归遍历 change check
      this.recursiveDownChangeCheck(node, checked);
      if (checked) {
        // 向上递归遍历 change check
        this.recursiveUpChangeCheck(node);
      }

      this.$emit('edited');
    },

    /**
     * 递归遍历子级，修改勾选状态
     * @param { Node } node 节点对象
     * @param { Boolean } checked 是否是勾选状态
     * @param { Boolean } syncAll 是否同步 checkAll 状态
     */
    recursiveDownChangeCheck (node, checked, syncAll=true) {
      if (node.data.checked!==checked && node.visible) {
        node.checked = checked;
        node.data.checked = checked;

        this.handleSyncCheckState(node.data[this.store.key], node.data, checked);
        if (syncAll) this.handleSyncCheckAllState();
      }

      if (!node.isLeaf) {
        node.children.forEach(child => {
          // 节点取消勾选，如果子节点是未勾选状态，则不需要再向下遍历（基于“勾子必勾父”逻辑）
          // 使用 child.data.checked 判断，因为 tree 是异步渲染，child.checked 可能还未正确赋值
          if (checked || (!checked && child.data.checked)) {
            this.recursiveDownChangeCheck(child, checked, syncAll);
          }
        });
      }
    },

    /**
     * 递归遍历父级，修改勾选状态
     * @note 递归过程跳过“root”节点
     * @param { Node } node 节点对象
     */
    recursiveUpChangeCheck (node) {
      if (node.level > 1) {
        let parent = node.parent;
        let indeterminate = parent.children.some(item => item.checked);
        if (indeterminate && !parent.data.checked) {
          parent.checked = indeterminate;
          parent.data.checked = indeterminate;
          
          this.handleSyncCheckState(parent.data[this.store.key], parent.data, indeterminate);
          this.handleSyncCheckAllState();
        }
        // 父节点勾选，如果祖父节点是勾选状态，则不需要再向上遍历（基于“勾子必勾父”逻辑）
        if (indeterminate && !parent.parent.checked) {
          this.recursiveUpChangeCheck(parent);
        }
      }
    },


    /**
     * 递归遍历子级，获取勾选项 key
     * @note 父节点未勾选，子节点不会存在勾选，无需遍历
     */
    recursiveDownGetCheck (list) {
      let checks = [];
      list.forEach(item => {
        if (item.checked) {
          checks.push(item[this.store.key]);

          if (item && item.children && item.children.length) {
            const childChecks = this.recursiveDownGetCheck(item.children);
            checks.push(...childChecks);
          }
        }
      });
      return checks;
    },



    /** 父组件调用 **/
    /**
     * 获取数据对象 checked keys
     * @note 通过递归遍历方案
     * @param { Array } data 项目权限资源数据
     * @returns { Array } checked keys
     */
    getProjectCheckedKeys (data) {
      return this.recursiveDownGetCheck(data);
    },

    /**
     * 获取总节点数量
     * @returns { Number } 节点数量
     */
    getProjectNodeCount () {
      if (this.store) {
        return Object.keys(this.store.nodesMap).length;
      } else {
        return 0;
      }
    },

    /**
     * 设置所有节点勾选状态
     * @param { Boolean } state 勾选状态
     */
    setCheckAllState (state) {
      this.root.children.forEach(node => {
        this.recursiveDownChangeCheck(node, state, false);
      });
      this.handleSyncCheckAllState();
    }
  }
}
