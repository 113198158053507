<template>
<div class="org-account-container">
    <div class="top-wrapper">
      <div class="search-container">
        <el-form inline :label-width="'0px'" :model="searchParam">
          <el-form-item prop="state">
            <el-select :placeholder="$t('状态')" v-model="searchParam.state">
              <el-option :label="$t('启用')" :value="1"></el-option>
              <el-option :label="$t('停用')" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="org-search-btn" type="info" icon="el-icon-search" @click="handleSearch">{{$t('查询')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tt-operate-container">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">{{$t('新增')}}</el-button>
        <!-- <el-button type="primary">启用</el-button>
        <el-button type="danger">停用</el-button> -->
      </div>
    </div>
    <div class="content-wrapper" v-loading="loading">
      <el-table :data="memberList">
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="userName" :label="$t('姓名')"></el-table-column>
        <el-table-column prop="userAccount" :label="$t('账号')"></el-table-column>
        <el-table-column prop="state" :label="$t('状态')">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state == 1" size="small" type="success" effect="plain">{{$t('启用')}}</el-tag>
            <el-tag v-else size="small" type="danger" effect="plain">{{$t('停用')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createUser" :label="$t('创建人')"></el-table-column>
        <el-table-column prop="createTime" :label="$t('创建时间')"></el-table-column>
        <el-table-column prop="operator" :label="$t('修改人')"></el-table-column>
        <el-table-column prop="updateTime" :label="$t('修改时间')"></el-table-column>
        <el-table-column prop="operate" width="120px" fixed="right" :align="'center'" :label="$t('操作')">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="()=>{handleEdit(scope.row)}">{{$t('编辑')}}</el-button>
            <el-button type="text" size="mini" @click="()=>{handleUpdatePwd(scope.row)}">{{$t('重置密码')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-container">
        <el-pagination layout="prev, pager, next, jumper" background @current-change="getMemberList" :current-page.sync="pagination.page" :total="pagination.total" :page-size="pagination.size"></el-pagination>
      </div>
    </div>
    <OrgAccount v-model:visible="dialogVisible" :detail="editData" @submit:success="getMemberList"></OrgAccount>
    <UpdatePWD v-model:visible="pwdVisible" :detail="editData"></UpdatePWD>
  </div>
</template>

<script>
import { intl } from "@tci18n/vue2";import memberAPI from '../api/member';
import OrgAccount from './account.vue';
import UpdatePWD from './updatePWD.vue';

export default {
  components: {
    OrgAccount,
    UpdatePWD
  },
  data() {
    return {
      memberList: [],
      sectionList: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      searchParam: {
        state: 1
      },
      loading: false,
      dialogVisible: false,
      editData: {},
      pwdVisible: false

    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    getMemberList() {
      this.loading = true;
      const params = {
        pageIndex: this.pagination.page,
        pageSize: this.pagination.size,
        includeChildDept: 1, //是否包含子部门员工 1-包含 0-不包含
        state: this.searchParam.state //0-无效 1-有效 null-全部
      };
      memberAPI.
      memberListPage(params).
      then((res) => {
        if (res.code == 200) {
          if (res?.data?.datas && (res?.data?.datas || []).length > 0) {
            this.memberList = res?.data?.datas || [];
            this.pagination.total = res?.data?.total || 0;
            this.pagination.page = res?.data?.currPage || 1;
          } else {
            this.memberList = [];
            this.pagination.total = 0;
            this.pagination.page = 1;
          }
        } else {
          this.$notice.error(res.message);
        }
      }).
      catch((error) => {
        this.$notice.error(intl.$t("系统异常，请稍后再试"));
        console.error('Get member list error: ', error.message);
      }).
      finally(() => {
        this.loading = false;
      });
    },
    handleSearch() {
      this.pagination.page = 1;
      this.getMemberList();
    },
    handleEdit(row) {
      this.editData = row;
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    handleAdd() {
      this.editData = {};
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    handleUpdatePwd(row) {
      this.editData = row;
      this.$nextTick(() => {
        this.pwdVisible = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.org-account-container {
  min-height: 100px;
  padding-top: 16px;
}
.top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
.search-container {
  :deep(.el-form-item) {
    margin-bottom: 0 !important;
  }
  .org-search-btn{
    background: #F5F7FD;
    border-color:#F5F7FD;
    color: #283145;
  }
}
.content-wrapper {
  :deep(.el-table th.el-table__cell > .cell) {
    padding-left: 14px !important;
  }
  :deep(.el-table .el-table__cell) {
    padding: 6px 0 !important;
  }
  :deep(.el-table td.el-table__cell),
  :deep(.el-table th.el-table__cell.is-leaf) {
    border-color: #E5E8F0 !important;
  }
  :deep(.el-table--fit) {
    border-color: #E5E8F0 !important;
  }
  :deep(.el-table th.el-table__cell) {
    background-color: #F5F7FD !important;
  }
  :deep(.el-table .el-table__cell) {
    font-weight: 400;
    font-size: 14px;
    color: #575B6B;
  }
  :deep(.el-table th.el-table__cell > .cell){
    font-weight: 500;
    font-size: 14px;
    color: #283145;
  }
  .page-container {
    padding-top: 16px;
    text-align: right;
  }
}
</style>
