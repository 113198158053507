import config from '@/config'
import Ajax from '@/libs/ajax'

const ajax = new Ajax(config.serverConfig.serviceURL);

/** 酒店相关 **/
// 城市酒店树
const hotelTreeByRegion = ajax.post(config.apiConf.serviceApi + 'hotel/getHotelTreeByRegion');

/** 密码相关 **/
// 自动生成密码
const pwdAutoCreate = ajax.post(config.apiConf.serviceApi + 'pwd/autoCreate');
// 生成随机密码
const pwdGenerate = ajax.post(config.apiConf.serviceApi + 'pwd/generate');
// 重置密码
const pwdReset = ajax.post(config.apiConf.serviceApi + 'pwd/reset');



/** 酒管相关 **/
// 租户关联的酒管组列表
const tenantBlocSetSelectList = ajax.post(config.apiConf.serviceApi + 'roleInfo/selectBlocs');
// 检查是否是内部连锁
const checkInternalBloc = ajax.post(config.apiConf.serviceApi + 'bloc/checkInternalBloc');
// 获取“酒管”数据权限级联下拉列表
const getBlocCascadeList = ajax.post(config.apiConf.serviceApi + 'brand/tree');


export default {
  hotelTreeByRegion,
  pwdAutoCreate,
  pwdGenerate,
  pwdReset,

  tenantBlocSetSelectList,
  checkInternalBloc,
  getBlocCascadeList
}
