<template>
  <div v-if="showable" :class="['root-node', { enabled: !disbaled }, 'display__flex', 'align_items__center', 'justify_content__space_between']">
    <div class="tree-row__prefix flex_grow__1 display__flex align_items__center" @click="handleNodeCurrentChange">
      <lz-icon v-if="title" style="margin-left: 8px;" type="iconfont" name="tree-folder__icon lzicon-folder" :size="14"></lz-icon>
      <span :title="title" :class="['tree-node__title is-root', { selected: selected }]">{{ title }}</span>
    </div>
    <div v-if="!disbaled" class="suffix-view tree-row__suffix">
      <div v-if="addable" class="tree-row__action__item" @click="handleAddNodeCallback">
        <i class="iconfont lzicon-add_circle_outline"></i>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

/**
 * 树 head 视图
 * @module @/view/organization
 */
export default {
  name: "TreeHead",
  props: {
    // 引用源（组织架构：dept，角色：role）
    refer: {
      type: String,
      default: 'dept'
    },

    // 根节点数据
    rootData: {
      type: Object
    },

    // 是否有“新增”权限
    addable: Boolean
  },
  computed: {
    ...mapGetters('org', [
      'activeDeptId'
    ]),

    // 引用来源是否是“部门”
    isReferDept () {
      return this.refer === 'dept';
    },

    // root 节点是否显示
    showable () {
      return this.rootData != null;
    },

    // root 节点是否是 disabled 状态
    disbaled () {
      return !(this.isReferDept && this.rootData && this.rootData.permission);
    },

    // 节点是否是 selected 状态
    selected () {
      return !this.disbaled && this.rootData && this.rootData.deptId==this.activeDeptId;
    },

    // 节点 title
    title () {
      return this.rootData && this.rootData.deptName || '';
    },
  },
  methods: {
    /**
     * 节点选择事件
     */
    handleNodeCurrentChange () {
      if (!this.disbaled) {
        this.$emit('current-change');
      }
    },

    /**
     * 新增节点事件
     */
    handleAddNodeCallback () {
      this.$emit('add');
    },
  }
}
</script>

<style lang="scss" scoped src="../common/tree.scss"></style>
<style lang="scss" scoped>
  .root-node {
    height: 40px;

    &.enabled {
      cursor: pointer;
    }

    &.enabled:hover {
      background-color: #EDF3FF;
    }
  }
</style>
